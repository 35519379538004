import { SearchInfo } from '@app-types/search';
import { MailFolderType } from '@app-types/enums/mail-folder.type';
import { isJSON } from '@app-utils/common';

export const encodeInfoMailAccount = (
  mailAccountId: number,
  folderId: number,
  typeFolder: MailFolderType,
  searchParams?: { [key: string]: any }
): string => {
  if (!mailAccountId || !folderId) {
    return '';
  }
  const params = {
    acc: String(mailAccountId),
    folder: String(folderId),
    type: typeFolder,
    ...(searchParams ?? {}),
  };
  return window.btoa(encodeURIComponent(JSON.stringify(params)));
};

export const decodeInfoMailAccount = (search: string): SearchInfo => {
  const infoAtob = decodeURIComponent(window.atob(search));
  if (!isJSON(infoAtob)) return {};
  const parseData: SearchInfo = JSON.parse(infoAtob);
  const newData: SearchInfo = {};
  for (const key in parseData) {
    if (Object.hasOwn(parseData, key)) {
      if (key === 'search') {
        newData.search = parseData.search;
      } else if (key === 'hideNotifications') {
        newData.hideNotifications = !!parseData.hideNotifications;
      } else if (key === 'filter') {
        newData.filter = parseData.filter;
      } else {
        newData[key] = +parseData[key];
      }
    }
  }
  return newData;
};
