import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BreadcrumbItem } from '@app-types/common';
import { UserContract, UserUpdateRequest } from '@app-types/api/user';
import { UserClient } from '@app-services/api/clients/user.client';
import { PermissionService } from '@app-services/permission/permission.service';
import { PermissionType } from '@app-types/enums/permission-type';
import { UserOrganizationClient } from '@app-services/api/clients/user-organization.client';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@app-environments/environment';
import { OrganizationSettingsClient } from 'src/app/services/api/clients/organization-settings.client';
import { BaseGetByIdRequest } from 'src/app/types/base/base';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  LOCALE_IDS,
  STORAGE_NAMES,
  WEB_CODE,
} from 'src/app/shared/constants/constants';
import { FormatAccountLanguage } from 'src/app/shared/pipes/format-account-language.pipe';
import { MatTabsModule } from '@angular/material/tabs';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { LocalStorageService } from '@app-services/local-storage.service';
import { UserWorkspaceService } from '@app-services/user-workspace-service';
import { DrawerService } from '@app-services/drawer.service';
import { AccountUpdateDialogComponent } from '@app-components/account-update-dialog/account-update-dialog.component';
import { GeneralSettingsSkeletonComponent } from '@app-components/common/skeletons/general-settings-skeleton/general-settings-skeleton.component';
import { AuthenticationService } from '@app-services/auth/authentication.service';

const { USER_LOCALE } = STORAGE_NAMES;

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
  standalone: true,
  imports: [
    MatTabsModule,
    SomethingWentWrongComponent,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    FormatAccountLanguage,
    AccountUpdateDialogComponent,
    GeneralSettingsSkeletonComponent,
  ],
})
export class AccountSettingsComponent implements OnInit {
  public breadcrumbItems: BreadcrumbItem[] = [
    { index: 0, label: 'accountSettingsBreadCrumbs', route: null },
  ];
  public isLoading = false;
  public isUploaded = false;
  public isError = false;
  public iscredemISAMSupport = false;
  public user: UserContract;
  public canChangePassword = false;
  public webCode: string;
  public organizationId: number;
  public locales = LOCALE_IDS;

  @ViewChild('updateDrawer') updateDrawer: TemplateRef<any>;

  constructor(
    private userClient: UserClient,
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    public matchError: MatchError,
    private organizationSettingsClient: OrganizationSettingsClient,
    private userOrganizationClient: UserOrganizationClient,
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
    private userWorkspaceService: UserWorkspaceService,
    public drawerService: DrawerService,
    private authenticationService: AuthenticationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.webCode = this.route.parent?.snapshot.paramMap.get(WEB_CODE) ?? '';
    await this.loadUser();
  }

  public async loadUser(): Promise<void> {
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.isLoading = true;
    }, 500);
    this.isError = false;
    try {
      const organizationId =
        this.userWorkspaceService.currentOrganization?.organizationId;
      if (organizationId) {
        const organizationSettings =
          await this.organizationSettingsClient.getByOrganization(
            new BaseGetByIdRequest(organizationId)
          );
        this.iscredemISAMSupport =
          organizationSettings.result.credemISAMSupport;
      }
      this.user = this.userWorkspaceService.user;
      await this.loadCanChangePassword();
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isUploaded = true;
      this.isLoading = false;
    }
  }
  public onChangePassword(): void {
    const fullURL = location.href;
    window.location.href = `${environment.apiOauthUrl}?returnUrl=${fullURL}`;
  }

  private async loadCanChangePassword(): Promise<void> {
    if (
      await this.permissionService.hasPermissionOver(
        null,
        null,
        PermissionType.CanEverything
      )
    ) {
      this.canChangePassword = false;
      return;
    }
    this.canChangePassword =
      !!this.userWorkspaceService.userOrganisaions?.length;
  }

  openUpdateUserDialog(): void {
    this.drawerService.openDrawer(this.updateDrawer);
  }

  async onUpdateUser(name: UserUpdateRequest): Promise<void> {
    this.drawerService.closeDrawer();
    this.user.firstName = name.firstName;
    this.user.lastName = name.lastName;
    this.user.localizationCode = name.localizationCode;
    this.localStorageService.setData(USER_LOCALE, this.user.localizationCode);
    this.user.localizationCode &&
      this.translate.use(this.user.localizationCode);
  }

  async logoutOIDC(): Promise<void> {
    await this.authenticationService.logoutOIDC(this.webCode);
  }
}
