import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  OrganizationContract,
  OrganizationCreateResponse,
} from '@app-types/api/organization';
import { OrganizationClient } from '@app-services/api/clients/organization.client';
import { OrganizationType } from '@app-types/enums/organization-type';
import { MatchError } from '@app-services/errors/error-matcher';
import { PignorState } from 'src/app/types/enums/pignor-type';
import { ClaimClient } from 'src/app/services/api/clients/claim';
import { OrganizationSettingsClient } from 'src/app/services/api/clients/organization-settings.client';
import { LoadingButtonComponent } from '../../../common/loading-button/loading-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ErrorHintComponent } from '../../../common/error-hint/error-hint.component';
import { RoleType } from '@app-types/enums/role-type';
import { DrawerService } from '@app-services/drawer.service';
import { SamlClaims } from '@app-types/api/mail-account';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { SpaceUsedPaymentPlanSelectTypesWithNull } from '@app-shared/constants/space-used';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { SkeletonFormDrawerComponent } from '@app-components/common/skeletons/skeleton-form-drawer/skeleton-form-drawer.component';
import { OrganizationSettingsContract } from '@app-types/api/Settings';

@Component({
  selector: 'app-organization-create-dialog',
  templateUrl: './organization-create-dialog.component.html',
  styleUrls: ['./organization-create-dialog.component.scss'],
  standalone: true,
  imports: [
    LoadingButtonComponent,
    TranslateModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    ErrorHintComponent,
    SkeletonFormDrawerComponent,
    SomethingWentWrongComponent,
  ],
})
export class OrganizationCreateDialogComponent implements OnInit, OnDestroy {
  @Output() public creationSubmit = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();
  @Input() organization: OrganizationContract | null;
  public loadingParentOrganizations = false;
  public cannotLoadParentOrganizations = false;
  public availableParentOrganizations: OrganizationContract[];
  public isError: boolean;
  public isLoading: boolean;
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public organizationTypes = OrganizationType;
  public availableOrganizationTypes = [
    OrganizationType.Company,
    OrganizationType.Group,
  ];
  public parentOrganization: OrganizationContract | null;
  public oidcSupport = true;
  public adfsSupport = false;
  public googleOAuthSupport = true;
  public credemISAMSupport = false;
  public pignor = false;
  public spaceUsedPaymentPlanType = SpaceUsedPaymentPlanSelectTypesWithNull;
  public samlClaims: SamlClaims;
  settings: OrganizationSettingsContract;

  constructor(
    private organizationClient: OrganizationClient,
    public claimClient: ClaimClient,
    public matchError: MatchError,
    public organizationSettingsClient: OrganizationSettingsClient,
    private drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAvailableParentOrganization();
    if (this.organization) {
      this.pignor = this.organization.pignorState === PignorState.Active;
      this.parentOrganization =
        this.availableParentOrganizations.find(
          o => o.organizationId === this.organization?.parentOrganizationId
        ) ?? null;
    }

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.organization?.name ?? '', [
        Validators.required,
        Validators.maxLength(256),
      ]),
      code: new UntypedFormControl(this.organization?.code ?? '', [
        Validators.required,
        Validators.maxLength(256),
      ]),
      samlRtaClaim: new UntypedFormControl(
        this.samlClaims?.claimCode || '',
        []
      ),
      type: new UntypedFormControl(
        this.organization?.type ?? OrganizationType.Company,
        [Validators.required]
      ),
      codeSia: new UntypedFormControl(this.organization?.codeSia ?? '', [
        Validators.required,
        Validators.maxLength(15),
      ]),
      licenseId: new UntypedFormControl(this.organization?.licenseId ?? '', [
        Validators.required,
        Validators.maxLength(128),
      ]),
      webCode: new UntypedFormControl(this.organization?.webCode ?? '', [
        Validators.required,
        Validators.maxLength(32),
        Validators.pattern('^([a-z][a-z0-9_]*)(-[a-z0-9]+)*$'),
      ]),
      anagId: new UntypedFormControl(this.organization?.anagId ?? '', [
        Validators.required,
      ]),
      spaceUsedPaymentPlanType: new UntypedFormControl(
        this.organization?.spaceUsedPaymentPlanType ?? null,
        [Validators.required]
      ),
    });
    if (!this.organization) {
      this.form.controls.samlRtaClaim.disable();
    } else {
      if (this.credemISAMSupport) {
        this.form.controls.samlRtaClaim.setValidators([
          Validators.required,
          Validators.maxLength(256),
        ]);
      } else {
        this.form.controls.samlRtaClaim.setValidators([]);
        this.form.controls.samlRtaClaim.disable();
      }
      this.form.controls.samlRtaClaim.updateValueAndValidity();
    }
  }

  public async loadAvailableParentOrganization(): Promise<void> {
    this.loadingParentOrganizations = true;
    this.cannotLoadParentOrganizations = false;
    try {
      if (this.organization) {
        const organizationClaimRespone =
          await this.claimClient.getByIdRtaClaimByOrganizationId(
            new BaseGetByIdRequest(this.organization.organizationId)
          );
        this.samlClaims = organizationClaimRespone?.result;

        const organizationSettingsResponse =
          await this.organizationSettingsClient.getByOrganization(
            new BaseGetByIdRequest(this.organization.organizationId)
          );
        this.settings = organizationSettingsResponse.result;
        this.credemISAMSupport = this.settings.credemISAMSupport;
        this.adfsSupport = this.settings.adfsSupport;
        this.googleOAuthSupport = this.settings.googleOAuthSupport;
        this.oidcSupport = this.settings.oidcSupport;
      }

      const response =
        await this.organizationClient.getAvailableParentOrganizations();
      this.availableParentOrganizations = this.organization
        ? response.data.filter(
            o => this.organization?.organizationId !== o.organizationId
          )
        : response.data;
    } catch (e) {
      this.cannotLoadParentOrganizations = true;
      this.matchError.logError(e);
    } finally {
      this.loadingParentOrganizations = false;
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form?.controls?.[controlName]?.hasError(errorName);
  };

  public onSamlChange(isChecked: boolean): void {
    if (isChecked) {
      this.form.controls.samlRtaClaim.setValidators([
        Validators.required,
        Validators.maxLength(256),
      ]);
      this.form.controls.samlRtaClaim.enable();
      this.googleOAuthSupport = false;
      this.oidcSupport = false;
    } else {
      this.form.controls.samlRtaClaim.disable();
      this.form.controls.samlRtaClaim.setValidators([]);
    }
    this.form.controls.samlRtaClaim.markAsTouched();
    this.form.controls.samlRtaClaim.updateValueAndValidity();
  }

  async onSubmit(organizationContract: OrganizationContract): Promise<void> {
    if (
      this.organization
        ? this.form.valid
        : this.form.valid && this.authTypeIsSelected
    ) {
      this.isLoading = true;
      this.drawerService.disabledDrawer(true);
      this.isError = false;
      try {
        if (this.organization) {
          organizationContract.organizationId =
            this.organization.organizationId;
          organizationContract.parentOrganizationId =
            this.parentOrganization?.organizationId ?? null;
          organizationContract.pignorState = this.pignor
            ? PignorState.Active
            : PignorState.Disabled;
        }

        const response = this.organization
          ? await this.organizationClient.update(organizationContract)
          : await this.organizationClient.create({
              name: organizationContract.name,
              code: organizationContract.code,
              type: organizationContract.type,
              parentOrganizationId:
                this.parentOrganization?.organizationId ?? null,
              codeSia: organizationContract.codeSia,
              licenseId: organizationContract.licenseId,
              webCode: organizationContract.webCode,
              anagId: organizationContract.anagId,
              pignorState: this.pignor
                ? PignorState.Active
                : PignorState.Disabled,
            });
        const organizationId = (response as OrganizationCreateResponse)
          ?.organizationId;

        const updateRequest = {
          ...(this.settings ?? {}),
          organizationId: this.organization
            ? this.organization.organizationId
            : organizationId,
          credemISAMSupport: this.credemISAMSupport,
          googleOAuthSupport: this.googleOAuthSupport,
          adfsSupport: this.adfsSupport,
          oidcSupport: this.oidcSupport,
          spaceUsedPaymentPlanType: this.organization
            ? this.form.controls.spaceUsedPaymentPlanType.value
            : organizationContract.spaceUsedPaymentPlanType,
        };

        await this.organizationSettingsClient.createOrUpdate(updateRequest);

        if (this.credemISAMSupport) {
          const updateRequestSamlClaims = {
            claimId: this.samlClaims ? this.samlClaims.claimId : null,
            claimCode: this.form.controls.samlRtaClaim.value,
            organizationId: this.organization
              ? this.organization.organizationId
              : organizationId,
            role: RoleType.Rta,
          };

          await this.claimClient.createOrUpdateRtaClaimByOrganizationId(
            updateRequestSamlClaims
          );
        }
        this.drawerService.disabledDrawer(false);
        this.creationSubmit.emit(organizationContract);
        this.drawerService.closeDrawer();
        this.form.reset();
      } catch (e) {
        this.isError = true;
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
        this.drawerService.disabledDrawer(false);
      } finally {
        this.isLoading = false;
      }
    }
  }

  public get authTypeIsSelected(): boolean {
    return (
      this.oidcSupport ||
      this.adfsSupport ||
      this.googleOAuthSupport ||
      this.credemISAMSupport
    );
  }

  ngOnDestroy(): void {
    this.closeEventEmitter && this.closeEventEmitter.emit();
  }
}
