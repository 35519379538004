import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MailFolderSettingsClient } from '@app-services/api/clients/mail-folder-settings.client';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { BaseGetByIdRequest } from 'src/app/types/base/base';
import { OrganizationClient } from 'src/app/services/api/clients/organization.client';
import { MailAccountSettingsClient } from 'src/app/services/api/clients/mail-account-settings.client';
import { PignorState } from 'src/app/types/enums/pignor-type';
import { MailFolderType } from 'src/app/types/enums/mail-folder.type';
import { DrawerService } from '@app-services/drawer.service';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingButtonComponent } from '../../common/loading-button/loading-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { SomethingWentWrongComponent } from '../../common/error/something-went-wrong/something-went-wrong.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MailFolderSettingsContract,
  MailFolderSettingsGetByMailFolderRequest,
} from '@app-types/api/mail-folder';
import { SkeletonFormDrawerComponent } from '@app-components/common/skeletons/skeleton-form-drawer/skeleton-form-drawer.component';

@Component({
  selector: 'app-mail-folder-settings-dialog',
  templateUrl: './mail-folder-settings-dialog.component.html',
  styleUrls: ['./mail-folder-settings-dialog.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    LoadingButtonComponent,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    SkeletonFormDrawerComponent,
    SomethingWentWrongComponent,
  ],
})
export class MailFolderSettingsDialogComponent implements OnInit {
  @Input() mailAccountId: number | null;
  @Input() organizationId: number;
  @Input() folderType?: MailFolderType;
  @Input() mailFolderId?: number;
  @Input() mailFolderName: string;
  @Output() public creationSubmit = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();

  public mailFolderType = MailFolderType;
  public settings: MailFolderSettingsContract;
  public form: UntypedFormGroup;
  public isLoading = false;
  public isUploaded = false;
  public cannotLoadSetting = false;
  public isSaving = false;
  public cannotSave = false;
  public pignor: boolean;
  public isShowPignorCheckbox: boolean;

  constructor(
    private mailAccountSettingsClient: MailAccountSettingsClient,
    private mailFolderSettingsClient: MailFolderSettingsClient,
    private organizationClient: OrganizationClient,
    public matchError: MatchError,
    private drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadData();

    this.form = new UntypedFormGroup({
      archive: new UntypedFormControl(this.settings.archive),
      canValidate: new UntypedFormControl(this.settings.canValidate),
      canReassign: new UntypedFormControl(this.settings.canReassign),
      code: new UntypedFormControl(this.settings.code),
    });
  }

  async loadData(): Promise<void> {
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.isLoading = true;
    }, 500);
    this.cannotLoadSetting = false;
    try {
      const organizationResponse = await this.organizationClient.getById(
        new BaseGetByIdRequest(this.organizationId)
      );
      const isOrganizationPignorActive =
        organizationResponse.organization.pignorState === PignorState.Active;
      if (isOrganizationPignorActive) {
        const mailAccountResponse =
          await this.mailAccountSettingsClient.getByMailAccount(
            new BaseGetByIdRequest(this.mailAccountId)
          );
        this.isShowPignorCheckbox =
          mailAccountResponse.result.pignorState === PignorState.Active;
      }
      if (this.mailAccountId && this.mailFolderId) {
        const response = await this.mailFolderSettingsClient.getByMailFolder(
          new MailFolderSettingsGetByMailFolderRequest(
            this.mailAccountId,
            this.mailFolderId
          )
        );
        this.pignor = response.result.pignorState === PignorState.Active;
        this.settings = response.result;
      }
    } catch (e) {
      this.cannotLoadSetting = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isUploaded = true;
      this.isLoading = false;
    }
  }

  async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.isSaving = true;
      this.drawerService.disabledDrawer(true);
      try {
        this.mailFolderId &&
          (await this.mailFolderSettingsClient.createOrUpdate({
            archive: this.form.controls.archive.value,
            canValidate: this.form.controls.canValidate.value,
            canReassign: this.form.controls.canReassign.value,
            code: this.form.controls.code.value,
            mailFolderId: this.mailFolderId,
            pignorState: this.pignor
              ? PignorState.Active
              : PignorState.Disabled,
          }));
        this.drawerService.disabledDrawer(false);
        this.creationSubmit.emit();
        this.drawerService.closeDrawer();
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
        this.drawerService.disabledDrawer(false);
      } finally {
        this.isSaving = false;
      }
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form?.controls?.[controlName]?.hasError(errorName);
  };

  public cancel(): void {
    this.closeEventEmitter.emit();
  }
}
