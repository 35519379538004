import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TemplateClient } from '@app-services/api/clients/template.client';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingButtonComponent } from '../../../../common/loading-button/loading-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { EmailBodyComponent } from '../../../../common/email/email-body/email-body.component';
import { DrawerService } from '@app-services/drawer.service';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { SomethingWentWrongComponent } from '../../../../common/error/something-went-wrong/something-went-wrong.component';
import { SkeletonEmailComponent } from '@app-components/common/skeletons/skeleton-email/skeleton-email.component';

@Component({
  selector: 'app-mail-account-template-create-dialog',
  templateUrl: './mail-account-template-create-dialog.component.html',
  styleUrls: ['./mail-account-template-create-dialog.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    LoadingButtonComponent,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    EmailBodyComponent,
    SomethingWentWrongComponent,
    SkeletonEmailComponent,
  ],
})
export class MailAccountTemplateCreateDialogComponent
  implements OnInit, OnDestroy
{
  @Input() mailAccountId: number;
  @Input() templateId: number | null;
  @Output() submitEventEmitter = new EventEmitter();
  @Output() closeEventEmitter = new EventEmitter();

  public isLoading = false;
  public isUploaded = false;
  public isError = false;
  public body = '';
  public isSendingRequest: boolean;

  public form = new UntypedFormGroup({
    templateName: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    templateDesc: new UntypedFormControl('', [Validators.maxLength(255)]),
    templateSubject: new UntypedFormControl('', [Validators.maxLength(255)]),
  });

  constructor(
    private templateClient: TemplateClient,
    public matchError: MatchError,
    private drawerService: DrawerService
  ) {}

  ngOnInit(): void {
    this.templateId ? this.loadTemplate() : (this.isUploaded = true);
  }

  public async loadTemplate(): Promise<void> {
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.isLoading = true;
    }, 500);
    this.isError = false;
    const request = new BaseGetByIdRequest(this.templateId);
    try {
      const response = await this.templateClient.getById(request);
      const template = response.template;
      this.form?.get('templateName')?.setValue(template.name);
      this.form?.get('templateDesc')?.setValue(template.description);
      this.form?.get('templateSubject')?.setValue(template.subject);
      this.body = template.body;
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isUploaded = true;
      this.isLoading = false;
    }
  }

  public isBodyHasData(): boolean {
    return !!this.body;
  }

  public async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.isSendingRequest = true;
      this.drawerService.disabledDrawer(true);
      const values = this.form.value;
      try {
        const data = {
          mailAccountId: this.mailAccountId,
          name: values.templateName,
          description: values.templateDesc,
          subject: values.templateSubject,
          body: this.body,
        };
        this.templateId
          ? await this.templateClient.update({
              templateId: this.templateId,
              ...data,
            })
          : await this.templateClient.create(data);
        this.drawerService.disabledDrawer(false);
        this.submitEventEmitter.emit();
        this.drawerService.closeDrawer();
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
        this.drawerService.disabledDrawer(false);
      } finally {
        this.isSendingRequest = false;
      }
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form?.controls?.[controlName]?.hasError(errorName);
  };

  valueChangedEventEmitter(event: string): void {
    this.body = event;
  }

  ngOnDestroy(): void {
    this.closeEventEmitter.emit();
  }
}
