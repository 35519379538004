<div class="page-content">
  @if(hasError) {
    <div class="h-[80%]">
       <app-something-went-wrong
        description="{{ 'cannotLoadMailAccount' | translate }}"
        [showPicture]="false"
        (retryEmitter)="ngOnInit()"></app-something-went-wrong>
    </div>
  }

  @if(isLoading) {
    <app-general-settings-skeleton></app-general-settings-skeleton>
  }

  @if(!isLoading && !hasError && mailAccount) {
    <div class="flex flex-col gap-10">
      <div class="flex flex-col gap-4">
        <div class="header-container flex-wrap xs:flex-nowrap">
          <h1>{{ "generalSettings" | translate }}</h1>
          <div class="buttons">
            <button mat-icon-button aria-label="Edit" (click)="onOpenUpdatingModalForm()">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button aria-label="Settings" (click)="openSettingsModalForm()">
              <mat-icon>settings</mat-icon>
            </button>
          </div>
        </div>
         <div class="self-start box-container">
            <div class="font-bold">{{ "name" | translate }}:</div>
            <div>{{ mailAccount.name }}</div>
            <div class="font-bold">{{ "license" | translate }}:</div>
            <div>{{ mailAccount.license }}</div>
            <div class="font-bold">{{ "codeSia" | translate }}:</div>
            <div>{{ mailAccount.codeSia }}</div>
             <div class="font-bold">{{ "anagId" | translate }}:</div>
            <div>{{ mailAccount.anagId }}</div>
             <div class="font-bold">{{ "email" | translate }}:</div>
            <div>{{ mailAccount.email }}</div>
             <div class="font-bold">{{ "providerLabel" | translate }}:</div>
            <div>{{ mailAccount.providerName }}</div>
            <div class="font-bold">{{ "emailState" | translate }}:</div>
            <div>
              <span class="{{ configurationCompleted === false ? 'text-red-600' : ''}}">
                {{ configurationCompleted === false ? ("notConfigured" | translate) : isActive ? ("active" | translate) : ("notActive" | translate) }}
              </span>
            </div>
            @if(configurationCompleted === false) {
              <div></div>
              <div class="flex gap-[0.5rem] items-center text-[12px]">
                <mat-icon class="text-red-600">warning</mat-icon>
                <div class="text-red-600">{{ "emailAccountIsNotCompleted" | translate }}</div>
              </div>
            }
            @if(mailAccount.connectionStatus) {
              <div class="font-bold">{{ "connect" | translate }}:</div>
              <div>
                  <span class="{{ connectionStatusName[mailAccount.connectionStatus].color }}">
                    {{ connectionStatusName[mailAccount.connectionStatus].title | translate }}
                  </span>
              </div>
              @if (!!mailAccount?.connectionStatusInfo || mailAccount.connectionStatus === 3) {
                <div></div>
                <div class="flex gap-[0.5rem] items-center text-[12px] {{ connectionStatusName[mailAccount.connectionStatus].color }}">
                  <mat-icon>warning</mat-icon>
                  <div>
                    {{ mailAccount.connectionStatus === 3
                    ? (connectionStatusName[mailAccount.connectionStatus].info | translate)
                    : mailAccount.connectionStatusInfo
                      ? (connectionStatusInfo[mailAccount.connectionStatusInfo] | translate)
                      : ''
                    }}
                  </div>
                </div>
              }
            }
          </div>
      </div>
      <div>
        <h2>{{ "additionalSettings" | translate }}</h2>
        <div class="buttons-panel">
          <button color="primary" mat-raised-button (click)="openChangePasswordModalForm()">
            <span class="button-icon">
              {{ "changePass" | translate }}
              <mat-icon>password</mat-icon>
            </span>
          </button>
          <button color="primary" mat-raised-button (click)="onOpenQuickSetupForm()">
            <span class="button-icon">
              {{ "quickSetup" | translate }}
              <mat-icon>bolt</mat-icon>
            </span>
          </button>
        </div>
      </div>
    </div>
  }
</div>

<ng-template #updateDrawer>
  <app-mail-account-update-dialog
    [mailAccount]="mailAccount"
    (submitEventEmitter)="onUpdateMailAccount($event)">
  </app-mail-account-update-dialog>
</ng-template>


<ng-template #settingsDrawer>
  <app-mail-account-settings-dialog
    [mailAccountId]="mailAccountId"
    [organizationId]="organizationId"
    (submitEventEmitter)="onUpdateSettings($event)">
  </app-mail-account-settings-dialog>
</ng-template>

<ng-template #changePasswordDrawer>
  <app-password-dialog
    [mailAccount]="mailAccount"
    (submitEventEmitter)="onChangedPassword()">
  </app-password-dialog>
</ng-template>

<ng-template #quickSetupDrawer>
  <app-mail-account-quick-setup-dialog [mailAccountId]="mailAccountId">
  </app-mail-account-quick-setup-dialog>
</ng-template>
