import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TagClient } from '@app-services/api/clients/tag.client';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingButtonComponent } from '../../../../common/loading-button/loading-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { DrawerService } from '@app-services/drawer.service';

@Component({
  selector: 'app-mail-account-tag-create-dialog',
  templateUrl: './mail-account-tag-create-dialog.component.html',
  styleUrls: ['./mail-account-tag-create-dialog.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    LoadingButtonComponent,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
  ],
})
export class MailAccountTagCreateDialogComponent {
  @Input() mailAccountId: number;
  @Output() submitEventEmitter = new EventEmitter();

  public isLoading = false;

  public form = new UntypedFormGroup({
    tag: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
  });

  constructor(
    private tagClient: TagClient,
    public matchError: MatchError,
    private drawerService: DrawerService
  ) {}

  public async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      this.drawerService.disabledDrawer(true);
      try {
        await this.tagClient.insert({
          mailAccountId: this.mailAccountId,
          tag: this.form.value.tag,
        });
        this.drawerService.disabledDrawer(false);
        this.submitEventEmitter.emit();
        this.drawerService.closeDrawer();
      } catch (e) {
        this.drawerService.disabledDrawer(false);
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form?.controls?.[controlName]?.hasError(errorName);
  };
}
