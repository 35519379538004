import { Component, Input, OnInit } from '@angular/core';
import { ExpressionOperation } from '@app-types/enums/expression.operation';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { toText } from '@app-utils/common';
import { EmailCondition } from '@app-types/api/Dispatching';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-dispatching-condition',
  templateUrl: './dispatching-condition.component.html',
  styleUrls: ['./dispatching-condition.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
  ],
})
export class DispatchingConditionComponent implements OnInit {
  @Input() public emailCondition: EmailCondition;
  @Input() public header: string;
  @Input() public paramName: string;
  public availableOperations: ExpressionOperation[] = [
    ExpressionOperation.Like,
    ExpressionOperation.NotLike,
    ExpressionOperation.Equal,
    ExpressionOperation.NotEqual,
  ];
  public form = new UntypedFormGroup({});

  constructor() {}

  ngOnInit(): void {
    if (!this.emailCondition.patterns.length) {
      this.emailCondition.patterns.push('');
    }
    this.initForm();
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({});
    for (let i = 0; i < this.emailCondition.patterns.length; i++) {
      this.form.addControl(
        `${this.paramName}-pattern-${i}`,
        new UntypedFormControl(this.emailCondition.patterns[i], [
          Validators.maxLength(256),
        ])
      );
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form?.controls?.[controlName]?.hasError(errorName);
  };

  public operationToText(operation: ExpressionOperation): string {
    return toText(operation);
  }

  public isLastPattern(index: number): boolean {
    return index !== this.emailCondition.patterns.length - 1;
  }

  public get conditionIsNotExists(): boolean {
    return !this.emailCondition;
  }

  public onCreate(): void {
    this.emailCondition.patterns.push('');
    this.initForm();
  }

  public onDelete(index: number): void {
    this.emailCondition.patterns.splice(index, 1);
    this.initForm();
  }

  trackByIndex(index: number): number {
    return index;
  }

  public onChangePattern(index: number, event: Event): void {
    this.emailCondition.patterns[index] = (
      event.target as HTMLInputElement
    ).value;
  }
}
