export const environment = {
  production: true,
  apiUrl: 'https://api-gateway.credemtel.it/pecmanager/',
  feUrl: 'https://pec.credemtel.it/',
  recaptchaSiteKey: '6LdR20IaAAAAAKqcx-xzc-NxDbwa8txdklsK7G2b',
  googleClientId:
    '857061325966-6n609am50lfju7rl9gjg6kpqk8hnj0sd.apps.googleusercontent.com',
  googleRedirectUrl: 'https://pm3.dev.platfoza.com/googleoauthcallback',
  googleAuthUrl: `https://accounts.google.com/o/oauth2/v2/auth`,
  apiOauthUrl: `https://api-oauth.credemtel.it/account/cambiopassword`,
  oidcSignOut: 'https://api-oauth.credemtel.it',
  azureAuthUrl: `https://login.microsoftonline.com/a6c1ea13-42d0-4567-9f2f-9e12e4713792/oauth2/v2.0/authorize?client_id=70c87da8-ef1d-47ba-bd54-d3a6d465806d&redirect_uri=https://pm3.dev.platfoza.com/adfsauthcallback&response_type=code&scope=openid%20profile&response_mode=query`,
  oidcSignInUri: 'https://pec.credemtel.it/sign-in/oidc',
  googleSignInUri: 'https://pec.credemtel.it/sign-in/google',
  oidcCredemSignInUri: 'https://pec.credemtel.it/sign-in/oidc-credem',
  renewUri: 'https://pec.credemtel.it/sign-in/renew',
  changeOrganizationUri: 'https://pec.credemtel.it/sign-in/changeorganization',
  pluginWebCodes: ['ferrero', 'pm'],
  autoSaveIntervalMs: 10000,
  userActivityTimeoutMs: 1000 * 60 * 60 * 3,
  userRenewTokenIntervalMs: 1000 * 60 * 5,
  language: 'it',
  systemUsers: ['alexey.gukezhev@esterni.credemtel.it'],
  cookieAnchor: 'jwtTokenAnchor',
  xApiKey: 'dGlu0C5esGgGaDHDw76v1GhusfUqPjQt',
};
