import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProviderContract } from '../../../../../../types/api/provider';
import { ProviderClient } from '../../../../../../services/api/clients/provider.client';
import { MailAccountClient } from '../../../../../../services/api/clients/mail-account.client';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ErrorCode } from '../../../../../../types/enums/error-code';
import { MailAccountContract } from '../../../../../../types/api/mail-account';
import { MatDialog } from '@angular/material/dialog';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LoadingButtonComponent } from '../../../../../common/loading-button/loading-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { DrawerService } from '../../../../../../services/drawer.service';

@Component({
  selector: 'app-mail-account-update-dialog',
  templateUrl: './mail-account-update-dialog.component.html',
  styleUrls: ['./mail-account-update-dialog.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    LoadingButtonComponent,
    TranslateModule,
  ],
})
export class MailAccountUpdateDialogComponent implements OnInit {
  @Input() public mailAccount: MailAccountContract;
  @Output() public submitEventEmitter = new EventEmitter<{
    name: string;
    codeSia: string;
    license: string;
    anagId: string;
  }>();
  public providers: ProviderContract[] = [];
  public form = new UntypedFormGroup({});
  public isLoading = false;
  public errorText: string;
  public errorCode: ErrorCode | null = null;

  public handlingErrorCodes = new Map<number, string>([]);

  constructor(
    private providerClient: ProviderClient,
    public matchError: MatchError,
    private mailAccountClient: MailAccountClient,
    public dialog: MatDialog,
    private drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.mailAccount.name, [
        Validators.required,
        Validators.maxLength(256),
      ]),
      codeSia: new UntypedFormControl(this.mailAccount.codeSia, [
        Validators.maxLength(255),
      ]),
      anagId: new UntypedFormControl(this.mailAccount.anagId, [
        Validators.maxLength(255),
      ]),
      license: new UntypedFormControl(this.mailAccount.license, [
        Validators.maxLength(255),
      ]),
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form?.controls?.[controlName]?.hasError(errorName);
  };

  async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      this.drawerService.disabledDrawer(true);
      this.errorCode = null;

      const values = this.form.value;
      const name = values.name;
      const codeSia = values.codeSia;
      const anagId = values.anagId;
      const license = values.license;

      const request = {
        mailAccountId: this.mailAccount.mailAccountId,
        name,
        codeSia,
        license,
        anagId,
      };

      try {
        await this.mailAccountClient.update(request);
        this.drawerService.disabledDrawer(false);
        this.submitEventEmitter.emit({ name, codeSia, license, anagId });
        this.drawerService.closeDrawer();
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
        this.drawerService.disabledDrawer(false);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
