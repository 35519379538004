<div class="page-container">
  <mat-tab-group class="items-start">
    <mat-tab label="{{ 'accountSettings' | translate }}"></mat-tab>
  </mat-tab-group>

  <div class="page-content">
    @if(isError) {
      <div class="h-[80%]">
        <app-something-went-wrong
          [showPicture]="true"
          (retryEmitter)="loadUser()"
        ></app-something-went-wrong>
      </div>
    }

    @if(isLoading) {
      <app-general-settings-skeleton
        [hasSubTitle]="false"
        [hasTitle]="false"
        [countLine]="3"
      ></app-general-settings-skeleton>
    }

    @if(!isError && !isLoading && !!isUploaded) {
     <div class="flex flex-col gap-10">
       <div class="self-start box-container">
          <div class="font-bold">
            {{ "name" | translate }}:
          </div>
          <div>
            {{ user.firstName + " " + user.lastName }}
          </div>
          <div class="font-bold">
            {{ "emailAccountSettings" | translate }}:
          </div>
          <div>
            {{ user.email }}
          </div>
          <div class="font-bold">
            {{ "language" | translate }}:
          </div>
          <div>
            {{ (user.localizationCode ?? '') | formatAccountLanguage }}
          </div>
          <div class="mt-4">
            <a
              class="text-primary cursor-pointer"
              (click)="logoutOIDC()"
            >
              {{ "logoutOIDC" | translate }}
            </a>
          </div>
       </div>
        @if(!iscredemISAMSupport) {
          <div class="buttons-panel">
            <button
              color="primary"
              aria-label="Edit"
              (click)="openUpdateUserDialog()"
              mat-raised-button
            >
              <span class="button-icon">
                 {{ "updateUser" | translate }}
                  <mat-icon class="text-[20px]">border_color</mat-icon>
              </span>
            </button>
            <button
              color="primary"
              (click)="onChangePassword()"
              mat-raised-button
            >
              <span class="button-icon">
                {{ "changePass" | translate }}
                <mat-icon>password</mat-icon>
              </span>
            </button>
          </div>
        }
    </div>
    }
  </div>
</div>

<ng-template #updateDrawer>
  <app-account-update-dialog
    [user]="user"
    [locales]="locales"
    (updateEventEmitter)="onUpdateUser($event)">
  </app-account-update-dialog>
</ng-template>
