import { IEventMetadata } from '@app-types/api/emails/email-audit';
import { ExpressionOperation } from '@app-types/enums/expression.operation';

export const makeId = (length: number): string => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const transformEnum = (
  e: any,
  keyOne: string = 'value',
  keyTwo: string = 'text'
) =>
  Object.keys(e)
    .filter(k => !(parseInt(k, 10) >= 0))
    .map(key => ({
      [keyOne]: e[key],
      [keyTwo]: key,
    }));

export const isEventMetadata = (
  typeToCheck: any
): typeToCheck is IEventMetadata =>
  !!(typeToCheck && typeof typeToCheck !== 'string');

export function toText(operation: ExpressionOperation): string {
  switch (operation) {
    case ExpressionOperation.Like:
      return 'contains';
    case ExpressionOperation.NotLike:
      return 'notContains';
    case ExpressionOperation.Equal:
      return 'equal';
    case ExpressionOperation.NotEqual:
      return 'notEqual';
    default:
      throw new Error('Not implemented ExpressionOperation: ' + operation);
  }
}

export const isJSON = (str: string): boolean => {
  try {
    JSON.parse(str);
    return true;
  } catch {
    return false;
  }
};
