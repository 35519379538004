import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MailFolderCreateRequest,
  MailFolderUpdateRequest,
} from '@app-types/api/mail-folder';
import { MailFolderType } from '@app-types/enums/mail-folder.type';
import { MailFolderClient } from '@app-services/api/clients/mail-folder.client';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { RemoteClient } from '@app-services/api/clients/remote.client';
import { ErrorsPipe } from '@app-pipes/error-code.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { LoadingButtonComponent } from '../../common/loading-button/loading-button.component';
import { SomethingWentWrongComponent } from '../../common/error/something-went-wrong/something-went-wrong.component';
import { MailCreationType } from '@app-types/enums/mail-folder-creation.type';
import { DrawerService } from '@app-services/drawer.service';
import { SkeletonFormDrawerComponent } from '@app-components/common/skeletons/skeleton-form-drawer/skeleton-form-drawer.component';

@Component({
  selector: 'app-mail-folder-create-dialog',
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    SkeletonFormDrawerComponent,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatButtonModule,
    LoadingButtonComponent,
  ],
  templateUrl: './mail-folder-create-dialog.component.html',
  styleUrls: ['./mail-folder-create-dialog.component.scss'],
})
export class MailFolderCreateDialogComponent implements OnInit, OnDestroy {
  @Input() mailFolderId?: number;
  @Input() mailFolderCreationType?: MailCreationType;
  @Input() mailFolderName?: string;
  @Input() providerFolderName?: string;
  @Input() parentMailFolderId: number | null;
  @Input() parentMailFolderType: number | null;
  @Input() mailAccountId: number;
  @Input() isSettings: boolean;
  @Output() public creationSubmit = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();

  public form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(256),
    ]),
  });
  public isLoading = false;
  public errorText: string;
  public mailCreationType = MailCreationType;
  public loadingAvailableData = false;
  public cannotLoadAvailableData = false;
  public isUploaded = false;
  public providerFolders: any[] = [];
  public selectedProviderFolder: string | null = null;
  pipeErrors = new ErrorsPipe();

  constructor(
    private mailFolderClient: MailFolderClient,
    public matchError: MatchError,
    private remoteClient: RemoteClient,
    private drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<any> {
    if (
      (this.parentMailFolderType === 2 || this.parentMailFolderType === 3) &&
      this.isSettings
    ) {
      await this.loadData();
      this.providerFolderName &&
        (this.selectedProviderFolder = this.providerFolderName);
    } else {
      this.isUploaded = true;
    }

    this.mailFolderName &&
      (this.form = new UntypedFormGroup({
        name: new UntypedFormControl(this.mailFolderName, [
          Validators.required,
          Validators.maxLength(256),
        ]),
      }));
  }

  async loadData(): Promise<any> {
    this.isUploaded = false;
    this.errorText = '';
    const loaderTimeout = setTimeout(() => {
      this.loadingAvailableData = true;
    }, 500);

    this.cannotLoadAvailableData = false;
    try {
      const response = await this.remoteClient.fetchRemoteFolders(
        new BaseGetByIdRequest(this.mailAccountId)
      );
      this.providerFolders = response.data.map(e => e.name);
      this.mailFolderName && this.providerFolders.unshift('--Clear--');
    } catch (e: any) {
      this.errorText =
        this.pipeErrors.transform(e.Code) ||
        `Error code: ${e.Code}: ${e.Description || 'something went wrong'}`;
      this.cannotLoadAvailableData = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isUploaded = true;
      this.loadingAvailableData = false;
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form?.controls?.[controlName]?.hasError(errorName);
  };

  public async onSubmit(formValue: any): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      this.drawerService.disabledDrawer(true);
      try {
        const request = {
          name: formValue.name,
          folderType: MailFolderType.Custom,
          providerFolderName: this.selectedProviderFolder,
        };
        this.mailAccountId &&
          ((request as MailFolderCreateRequest).mailAccountId =
            this.mailAccountId);
        this.mailFolderId &&
          ((request as MailFolderUpdateRequest).mailFolderId =
            this.mailFolderId);
        this.parentMailFolderId &&
          ((request as MailFolderUpdateRequest).parentId =
            this.parentMailFolderId);

        this.mailFolderId
          ? await this.mailFolderClient.update(
              request as MailFolderUpdateRequest
            )
          : await this.mailFolderClient.create(
              request as MailFolderCreateRequest
            );
        this.creationSubmit.emit();
        this.drawerService.disabledDrawer(false);
        this.drawerService.closeDrawer();
      } catch (e: any) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
        this.drawerService.disabledDrawer(false);
      } finally {
        this.isLoading = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.closeEventEmitter && this.closeEventEmitter.emit();
  }
}
