import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ProviderContract } from '@app-types/api/provider';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '@app-services/snackbar.service';
import { ProviderClient } from '@app-services/api/clients/provider.client';
import { PermissionNavTabHelper } from '@app-services/permission/permission-nav-tab-helper';
import { MatDialog } from '@angular/material/dialog';
import { MobileObserverService } from '@app-services/adaptive/mobile-observer.service';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NoDataComponent } from '@app-components/common/no-data/no-data.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { DrawerService } from '@app-services/drawer.service';
import { ProviderCreateDialogComponent } from '@app-components/settings/provider-create-dialog/provider-create-dialog.component';
import { DeleteDialogComponent } from '@app-components/common/delete-dialog/delete-dialog.component';
import { FormatTlsVersionPipe } from '@app-pipes/format-tls-version.pipe';
import { Subscription } from 'rxjs';
import { SkeletonTableComponent } from '@app-components/common/skeletons/skeleton-table/skeleton-table.component';
import {
  CONNECTION_STATUS,
  CONNECTION_STATUS_INFO_PROVIDER,
} from '@app-shared/constants/connection-status';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    SomethingWentWrongComponent,
    MatButtonModule,
    MatIconModule,
    NoDataComponent,
    SomethingWentWrongComponent,
    MatTableModule,
    MatTooltipModule,
    MatCardModule,
    ProviderCreateDialogComponent,
    FormatTlsVersionPipe,
    SkeletonTableComponent,
  ],
})
export class ProviderComponent implements OnInit, OnDestroy {
  @HostBinding('class') className = 'setting-container';
  public providers: ProviderContract[] = [];
  public dataSource = new MatTableDataSource<ProviderContract>(this.providers);
  public displayedColumns: string[] = [
    'name',
    'smtp',
    'smtp-port',
    'imap',
    'imap-port',
    'tlsVersion',
    'connect',
    'actions',
  ];
  public isLoading = false;
  public isUploaded = false;
  public isError = false;
  public updatingProvider: ProviderContract | null;
  public useMobileView = false;
  private useMobileViewSubscription: Subscription;
  @ViewChild('createDrawer') createDrawer: TemplateRef<any>;
  connectionStatusName = CONNECTION_STATUS;
  connectionStatusInfo = CONNECTION_STATUS_INFO_PROVIDER;

  constructor(
    public matchError: MatchError,
    protected router: Router,
    private snackbarHelper: SnackbarService,
    private providerClient: ProviderClient,
    private permissionNavTabHelper: PermissionNavTabHelper,
    public dialog: MatDialog,
    private mobileObserverService: MobileObserverService,
    private route: ActivatedRoute,
    private drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.useMobileViewSubscription = this.mobileObserverService
      .mobileObserver()
      .subscribe(isMobile => (this.useMobileView = isMobile));
    await this.loadProviders();
  }

  async loadProviders(): Promise<void> {
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.isLoading = true;
    }, 500);
    this.isError = false;
    try {
      const response = await this.providerClient.search();
      this.providers = response.providerContracts;
      this.dataSource.data = this.providers;
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isLoading = false;
      this.isUploaded = true;
    }
  }

  async onDeleteBtnClicked(providerId: number): Promise<void> {
    await this.providerClient.delete({ id: providerId });
  }

  async deleteProvider(
    providerId: number,
    providerName: string
  ): Promise<void> {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '450px',
      autoFocus: false,
      data: {
        title: 'confirmProviderDeletion',
        subTitles: [
          {
            title: 'wantToDelete',
            subTitle: providerName,
          },
          {
            title: 'provider',
            subTitle: '',
          },
        ],
        onDelete: async () => await this.onDeleteBtnClicked(providerId),
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.isDeleted) {
        this.providers = this.providers.filter(
          p => p.providerId !== providerId
        );
        this.dataSource.data = this.providers;
      }
    });
  }

  async onCreateProviderBtnClicked(): Promise<void> {
    this.drawerService.openDrawer(this.createDrawer);
  }

  async onCreateProvider(): Promise<void> {
    await this.loadProviders();
  }

  openUpdateProviderDialog(providerContract: ProviderContract): void {
    this.updatingProvider = providerContract;
    this.drawerService.openDrawer(this.createDrawer);
  }

  onCloseUpdatingModalForm(): void {
    this.updatingProvider = null;
  }

  async onUpdateProvider(): Promise<void> {
    await this.loadProviders();
    this.onCloseUpdatingModalForm();
  }

  ngOnDestroy(): void {
    this.useMobileViewSubscription?.unsubscribe();
  }
}
