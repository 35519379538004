@if(userWorkspaceService.mailAccounts.length) {
<div class="flex justify-between flex-wrap gap-y-2">
  <div class="flex gap-1 items-center w-full tablet:w-auto">
    <button
      class="h-[40px] !min-w-fit text-nowrap"
      mat-raised-button
      color="primary"
      (click)="!userWorkspaceService.isChangeAccount && createEmail()"
      matTooltip="{{ 'createEmail' | translate }}"
      [disabled]="isError"
    >
      <mat-icon class="mr-2 min-w-[24px]">mail_outline</mat-icon>
      {{ useTabletView ? ("newEmailMobile" | translate) : ("newEmail" | translate) }}
    </button>

    @if(isExporting) {
      <div class="w-[2.5rem]">
        <app-loader padding="0" [diameter]="'22'" height="100%"></app-loader>
      </div>
    } @else {
      @if(infoOpenedMailAccount?.type !== mailFolderType.Outbox) {
        <button
          [disabled]="isError"
          mat-icon-button
          [attr.aria-label]="useTabletView ? 'Export excel' : ('exportExcel' | translate)"
          (click)="!userWorkspaceService.isChangeAccount && exportExcel()"
          matTooltip="{{ useTabletView ? ('exportExcel' | translate) : ('exportExcelLabel' | translate) }}">
          <i class="far fa-file-excel fa-xs"></i>
        </button>
      }
    }
    @if(infoOpenedMailAccount?.type === mailFolderType.Inbox || infoOpenedMailAccount?.type === mailFolderType.Sent) {
      <button
        [disabled]="isError"
        mat-icon-button
        [attr.aria-label]="'importEML' | translate"
        (click)="!userWorkspaceService.isChangeAccount && importEML()"
        matTooltip="{{ 'importEML' | translate }}">
        <i class="fa-solid fa-upload fa-xs"></i>
      </button>
    }

    <div class="block 2xl:hidden icon-button-group flex justify-between items-center w-full">
      <div class="flex justify-center">
        @if(!!selection.selected.length && !selection.isEmpty()) {
          <div class="flex">
            <app-loading-icon-button
              label="validate"
              icon="check_circle_outline"
              [showButton]="
                showIconByIdSelected(emailIcons.Validate) && validateIsAvailable() && !validatingEmailIds.length
              "
              [showLoading]="!!validatingEmailIds.length"
              (clickButton)="setValidated(emailValidationState.Validated)"
            ></app-loading-icon-button>

            @if(showIconByIdSelected(emailIcons.Reply) && selection.selected.length === 1) {
              <button
                mat-icon-button
                class="max-xl:!hidden"
                [attr.aria-label]="'reply' | translate"
                (click)="reply()">
                <mat-icon>reply</mat-icon>
              </button>
            }

            @if(showIconByIdSelected(emailIcons.ReplyAll) && selection.selected.length === 1) {
              <button
                class="max-xl:!hidden"
                mat-icon-button
                [attr.aria-label]="'replyAll' | translate"
                (click)="replyAll()">
                <mat-icon>reply_all_outline</mat-icon>
              </button>
            }
            @if(showIconByIdSelected(emailIcons.Forward) && !!infoOpenedMailAccount?.acc) {
              <button
                class="max-xl:!hidden"
                mat-icon-button
                [attr.aria-label]="'forward' | translate"
                (click)="forward()">
                <mat-icon>shortcut</mat-icon>
              </button>
            }

          @if(showIconByIdSelected(emailIcons.Reassign) && multipleReassignIsAvailable && !searchParams.reassigned) {
            <button
              class="max-xl:!hidden"
              mat-icon-button
              [attr.aria-label]="'reassign' | translate"
              (click)="reassign()">
              <mat-icon>forward_to_inbox</mat-icon>
            </button>
          }

          @if(showIconByIdSelected(emailIcons.Groups) && !!infoOpenedMailAccount?.acc) {
          <button
            class="max-xl:!hidden"
            mat-icon-button
            aria-label="Groups"
            (click)="openAddGroupDialog()">
            <mat-icon>groups</mat-icon>
          </button>
          }
          @if(showIconByIdSelected(emailIcons.Tags) && !!infoOpenedMailAccount?.acc) {
          <button
            class="max-xl:!hidden"
            mat-icon-button
            [attr.aria-label]="'tags' | translate"
            (click)="openAddTagDialog()">
            <mat-icon>local_offer</mat-icon>
          </button>
          }


            @if(selection.selected.length !== 0) {
              <button mat-icon-button aria-label="Menu" [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
            }

            <mat-menu #menu="matMenu">
              @if(showIconByIdSelected(emailIcons.Reply) && selection.selected.length === 1) {
                <button
                  class="xl:!hidden"
                  mat-menu-item
                  [attr.aria-label]="'reply' | translate"
                  (click)="reply()">
                  <mat-icon>reply</mat-icon>{{ "reply" | translate }}
                </button>
              }
              @if(showIconByIdSelected(emailIcons.ReplyAll) && selection.selected.length === 1) {
              <button
                class="xl:!hidden"
                mat-menu-item
                [attr.aria-label]="'replyAll' | translate"
                (click)="replyAll()">
                <mat-icon>reply_all_outline</mat-icon>{{ "replyAll" | translate }}
              </button>
              }
              @if(showIconByIdSelected(emailIcons.Forward) && !!infoOpenedMailAccount?.acc) {
              <button
                class="xl:!hidden"
                mat-menu-item
                [attr.aria-label]="'forward' | translate"
                (click)="forward()">
                <mat-icon>shortcut</mat-icon>{{ "forward" | translate }}
              </button>
              }

              @if(showIconByIdSelected(emailIcons.Reassign) && multipleReassignIsAvailable && !searchParams.reassigned) {
              <button
                class="xl:!hidden"
                mat-menu-item
                [attr.aria-label]="'reassign' | translate"
                (click)="reassign()">
                <mat-icon>forward_to_inbox</mat-icon>{{ "reassign" | translate }}
              </button>
              }

              @if(showIconByIdSelected(emailIcons.Move) && !!infoOpenedMailAccount?.acc) {
              <button
                mat-menu-item
                aria-label="Move"
                (click)="move()">
                <mat-icon>folder_open</mat-icon> {{ "moveToFolder" | translate }}
              </button>
              }

              @if(showIconByIdSelected(emailIcons.Archive) && !!infoOpenedMailAccount?.acc) {
              <button
                mat-menu-item
                aria-label="Archive"
                (click)="setSelectedArchive()">
                <mat-icon>archive</mat-icon>{{ "archiveIconLabel" | translate }}
              </button>
              }

              @if(showIconByIdSelected(emailIcons.Groups) && !!infoOpenedMailAccount?.acc) {
              <button
                class="xl:!hidden"
                mat-menu-item
                aria-label="Groups"
                (click)="openAddGroupDialog()">
                <mat-icon>groups</mat-icon>{{ "addGroups" | translate }}
              </button>
              }
              @if(showIconByIdSelected(emailIcons.Tags) && !!infoOpenedMailAccount?.acc) {
              <button
                class="xl:!hidden"
                mat-menu-item
                [attr.aria-label]="'tags' | translate"
                (click)="openAddTagDialog()">
                <mat-icon>local_offer</mat-icon>{{ "addTags" | translate }}
              </button>
              }

              @if(showIconByIdSelected(emailIcons.Seen) && !!infoOpenedMailAccount?.acc) {
              <button
                mat-menu-item
                [attr.aria-label]="'markAsUnread'"
                (click)="setSeen(false)">
                <mat-icon>visibility_off_outline</mat-icon>{{ "markAsUnread" | translate }}
              </button>
              }

              @if(showIconByIdSelected(emailIcons.Seen) && selection.selected.length === 1) {
              <button
                mat-menu-item
                [attr.aria-label]="'audit'"
                (click)="openAudit()">
                <mat-icon>verified_outline</mat-icon>{{ "audit" | translate }}
              </button>
              }

              @if(showIconByIdSelected(emailIcons.Seen) && !!infoOpenedMailAccount?.acc) {
              <button
                mat-menu-item
                [attr.aria-label]="'markAsRead' | translate"
                (click)="setSeen(true)">
                <mat-icon>visibility_outline</mat-icon>{{ "markAsRead" | translate }}
              </button>
              }

              @if(showIconByIdSelected(emailIcons.Spam) && !!infoOpenedMailAccount?.acc) {
              <button
                mat-menu-item
                [attr.aria-label]="'markAsSpam' | translate"
                (click)="setSelectedSpam()">
                <mat-icon>report_outline</mat-icon>{{ "markAsSpam" | translate }}
              </button>
              }

              @if(showIconByIdSelected(emailIcons.Delete) && !!infoOpenedMailAccount?.acc) {
              <button
                mat-menu-item
                [attr.aria-label]="'deleteMail' | translate"
                (click)="deleteAllSelected()">
                <mat-icon>delete</mat-icon>{{ "delete" | translate }}
              </button>
              }

              @if((infoOpenedMailAccount?.type === mailFolderType.Trash || infoOpenedMailAccount?.type === mailFolderType.Spam) && canFinalDelete) {
                <button
                  mat-menu-item
                  [attr.aria-label]="'deleteFinalMail' | translate"
                  matTooltip="{{ 'deleteFinalMail' | translate }}"
                  (click)="finalDelete()"
                >
                  <mat-icon>delete_forever</mat-icon>{{ "deleteFinalMail" | translate }}
                </button>
              }

              @if(showIconByIdSelected(emailIcons.DownloadEML) && infoOpenedMailAccount?.acc) {
              <button
                mat-menu-item
                [attr.aria-label]="'downloadSingleEml' | translate"
                (click)="downloadSingleEMLSelection()">
                <mat-icon>download</mat-icon>{{ "downloadSingleEml" | translate }}
              </button>
              }

              @if(showIconByIdSelected(emailIcons.DownloadEML) && infoOpenedMailAccount?.acc) {
              <button
                mat-menu-item
                [attr.aria-label]="'downloadEml' | translate"
                (click)="downloadEMLSelection()">
                <mat-icon>download_for_offline</mat-icon>{{ "downloadEml" | translate }}
              </button>
              }

              @if(showIconByIdSelected(emailIcons.RestoreFromArchive) && infoOpenedMailAccount?.acc) {
              <button
                mat-menu-item
                [attr.aria-label]="'restoreFromArchive' | translate"
                (click)="restoreFromArchive()">
                <mat-icon>assignment_turned_in</mat-icon>{{ "restoreArchive" | translate }}
              </button>
              }

              @if(showIconByIdSelected(emailIcons.RetrySending) &&
              infoOpenedMailAccount?.acc &&
              isSelectedRetrySendingNeededState()) {
              <button
                mat-menu-item
                [attr.aria-label]="'retrySending' | translate"
                (click)="retrySend()">
                <mat-icon>redo</mat-icon>{{ "retrySending" | translate }}
              </button>
              }

              @if(showIconByIdSelected(emailIcons.Restore) && infoOpenedMailAccount?.acc) {
              <button
                mat-menu-item
                [attr.aria-label]="'restore' | translate"
                (click)="restoreFromTrash()">
                <mat-icon>restore_outline</mat-icon>{{ "restore" | translate }}
              </button>
              }

              @if(showIconByIdSelected(emailIcons.NotSpam) && infoOpenedMailAccount?.acc) {
              <button
                mat-menu-item
                [attr.aria-label]="'markAsNotSpam' | translate"
                (click)="restoreMoveFolderChoose(selection.selected, 'restoreSelectedSpam')">
                <mat-icon>check</mat-icon>{{ "markAsNotSpam" | translate }}
              </button>
              }
              @if(selection.selected.length === 1 && showMetaButton()) {
                <button mat-menu-item aria-label="meta" matTooltip="{{ 'meta' | translate }}" (click)="openMeta(selection.selected[0])">
                  <mat-icon>info_outline</mat-icon>{{ "meta" | translate }}
                </button>
              }
            </mat-menu>
          </div>
        }
      </div>
    </div>
  </div>

  <div class="max-2xl:hidden 2xl:flex icon-button-group items-center">
    @if(selection.selected.length && !selection.isEmpty()) {
      <div class="flex">
        <app-loading-icon-button
          label="validate"
          icon="check_circle_outline"
          [showButton]="
          showIconByIdSelected(emailIcons.Validate) &&
          validateIsAvailable() &&
          !!infoOpenedMailAccount?.acc &&
          !validatingEmailIds.length"
          [showLoading]="!!validatingEmailIds.length"
          (clickButton)="setValidated(emailValidationState.Validated)">
        </app-loading-icon-button>

        @if((infoOpenedMailAccount?.type === mailFolderType.Trash || infoOpenedMailAccount?.type === mailFolderType.Spam) && canFinalDelete) {
          <button
            mat-icon-button
            [attr.aria-label]="'deleteFinalMail' | translate"
            matTooltip="{{ 'deleteFinalMail' | translate }}"
            (click)="finalDelete()"
          >
            <mat-icon>delete_forever</mat-icon>
          </button>
        }

        @if(showIconByIdSelected(emailIcons.RetrySending) && infoOpenedMailAccount?.acc && isSelectedRetrySendingNeededState()) {
          <button
            mat-icon-button
            [attr.aria-label]="'retrySending' | translate"
            matTooltip="{{ 'retrySending' | translate }}"
            (click)="retrySend()"
          >
            <mat-icon>redo</mat-icon>
          </button>
        }
        <app-loading-icon-button
          label="reply"
          icon="reply"
          [showButton]="!replyingEmailId && showIconByIdSelected(emailIcons.Reply) && selection.selected.length === 1"
          [showLoading]="!!replyingEmailId"
          [disabled]="!!replyingAllEmailId || !!forwardingEmailId || !!replyingEmailId"
          (clickButton)="reply()"
        ></app-loading-icon-button>
        <app-loading-icon-button
          label="replyAll"
          icon="reply_all_outline"
          [showButton]="!replyingAllEmailId && showIconByIdSelected(emailIcons.ReplyAll) && selection.selected.length === 1"
          [showLoading]="!!replyingAllEmailId"
          [disabled]="!!replyingAllEmailId || !!forwardingEmailId || !!replyingEmailId"
          (clickButton)="replyAll()">
        </app-loading-icon-button>
        <app-loading-icon-button
          label="forward"
          icon="shortcut"
          [showButton]="!forwardingEmailId && showIconByIdSelected(emailIcons.Forward)"
          [showLoading]="!!forwardingEmailId"
          [disabled]="!!replyingAllEmailId || !!forwardingEmailId || !!replyingEmailId"
          (clickButton)="forward()">
        </app-loading-icon-button>

        @if(showIconByIdSelected(emailIcons.Reassign) && multipleReassignIsAvailable && !searchParams.reassigned) {
          <button
            mat-icon-button
            [attr.aria-label]="'reassign' | translate"
            (click)="reassign()"
            matTooltip="{{ 'reassign' | translate }}"
          >
            <mat-icon>forward_to_inbox</mat-icon>
          </button>
        }

        @if(showIconByIdSelected(emailIcons.Move) && infoOpenedMailAccount?.acc) {
          <button
            mat-icon-button
            aria-label="Move"
            (click)="move()"
            matTooltip="{{ 'moveToFolder' | translate }}"
          >
            <i class="far fa-folder fa-xs"></i>
          </button>
        }

        @if(showIconByIdSelected(emailIcons.Archive) && infoOpenedMailAccount?.acc) {
          <button
            mat-icon-button
            aria-label="Archive"
            (click)="setSelectedArchive()"
            matTooltip="{{ 'archiveIconLabel' | translate }}">
            <mat-icon>archive</mat-icon>
          </button>
        }

        @if(showIconByIdSelected(emailIcons.Groups) && infoOpenedMailAccount?.acc) {
          <button
            mat-icon-button
            [attr.aria-label]="'groups' | translate"
            matTooltip="{{ 'groups' | translate }}"
            (click)="openAddGroupDialog()"
          >
            <mat-icon>groups</mat-icon>
          </button>
        }

        @if(showIconByIdSelected(emailIcons.Tags) && infoOpenedMailAccount?.acc) {
          <button
            mat-icon-button
            [attr.aria-label]="'tags' | translate"
            matTooltip="{{ 'tags' | translate }}"
            (click)="openAddTagDialog()">
            <mat-icon>local_offer</mat-icon>
          </button>
        }

        @if(showIconByIdSelected(emailIcons.Seen) && selection.selected.length === 1) {
          <button
            mat-icon-button
            aria-label="Audit"
            (click)="openAudit()"
            matTooltip="{{ 'audit' | translate }}"
          >
            <mat-icon>verified_outline</mat-icon>
          </button>
        }

        @if(showIconByIdSelected(emailIcons.Seen) && infoOpenedMailAccount?.acc) {
          <button
            mat-icon-button
            aria-label="Read"
            (click)="setSeen(true)"
            matTooltip="{{ 'read' | translate }}">
            <mat-icon>visibility_outline</mat-icon>
          </button>
        }

        @if(showIconByIdSelected(emailIcons.Seen) && infoOpenedMailAccount?.acc) {
          <button
            mat-icon-button
            aria-label="Unread"
            (click)="setSeen(false)"
            matTooltip="{{ 'unread' | translate }}">
            <mat-icon>visibility_off_outline</mat-icon>
          </button>
        }

        @if(showIconByIdSelected(emailIcons.Spam) && infoOpenedMailAccount?.acc) {
          <button
            mat-icon-button
            [attr.aria-label]="'markAsSpam' | translate"
            (click)="setSelectedSpam()"
            matTooltip="{{ 'markAsSpam' | translate }}"
          >
            <mat-icon>report_outline</mat-icon>
          </button>
        }

        @if(showIconByIdSelected(emailIcons.Delete) && infoOpenedMailAccount?.acc) {
          <button
            mat-icon-button
            [attr.aria-label]="'delete' | translate"
            matTooltip="{{ 'delete' | translate }}"
            (click)="deleteAllSelected()">
            <mat-icon>delete</mat-icon>
          </button>
        }

        <app-loading-icon-button
          label="downloadSingleEml"
          icon="download"
          [showButton]="
            showIconByIdSelected(emailIcons.DownloadEML) && !!infoOpenedMailAccount?.acc && !isDownloadingSingleEML
          "
          [showLoading]="isDownloadingSingleEML"
          (clickButton)="downloadSingleEMLSelection()">
        </app-loading-icon-button>

        <app-loading-icon-button
          label="downloadEml"
          icon="download_for_offline"
          [showButton]="!!showIconByIdSelected(emailIcons.DownloadEML) && !!infoOpenedMailAccount?.acc && !isDownloadingEML"
          [showLoading]="isDownloadingEML"
          (clickButton)="downloadEMLSelection()">
        </app-loading-icon-button>

        @if(showIconByIdSelected(emailIcons.Restore) && infoOpenedMailAccount?.acc) {
          <button
            mat-icon-button
            [attr.aria-label]="'restore' | translate"
            matTooltip="{{ 'restore' | translate }}"
            (click)="restoreFromTrash()">
            <mat-icon>restore_outline</mat-icon>
          </button>
        }

        @if(showIconByIdSelected(emailIcons.RestoreFromArchive) && infoOpenedMailAccount?.acc) {
          <button
            mat-icon-button
            [attr.aria-label]="'restoreFromArchive' | translate"
            (click)="restoreFromArchive()"
            matTooltip="{{ 'restoreFromArchive' | translate }}">
            <mat-icon>assignment_turned_in</mat-icon>
          </button>
        }

        @if(showIconByIdSelected(emailIcons.NotSpam) && infoOpenedMailAccount?.acc) {
          <button
            mat-icon-button
            [attr.aria-label]="'markAsNotSpam' | translate"
            (click)="restoreMoveFolderChoose(selection.selected, 'restoreSelectedSpam')"
            matTooltip="{{ 'markAsNotSpam' | translate }}">
            <mat-icon>check</mat-icon>
          </button>
        }
      </div>
    }
  </div>

  @if(useMobileView && !isError) {
    <div class="flex flex-wrap-reverse items-center justify-between w-full md:mt-0 mt-2">
      <div class="w-full flex items-center flex-wrap justify-end">
        <div class="flex items-center justify-between w-full">
          <div>
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              color="primary"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            ></mat-checkbox>
          </div>
          <div class="flex items-center ml-[auto]">
            <app-search-order
              (reloadEventEmitter)="reload()"
              (searchOrderChangeEventEmitter)="searchOrderChange($event)"
              [searchOrder]="searchOrder"
              [searchOrders]="searchOrders"
            ></app-search-order>
          </div>
          <div class="">
            <app-paginator
              [countFrom]="countFrom()"
              [countTo]="countTo()"
              [countTotal]="countTotal()"
              [hasPreviousPage]="canNavigateBack"
              [hasNextPage]="canNavigateForward"
              [pageSize]="pageSize"
              [pageSizeOptions]="[25, 50, 100, 200]"
              (pageSizeChange)="pageSizeChange($event)"
              (goToPreviousPage)="navigateBack()"
              (goToNextPage)="navigateForward()">
            </app-paginator>
          </div>
        </div>
      </div>
    </div>
  }

  @if(!useMobileView && !isError) {
    <div class="flex flex-wrap-reverse items-center justify-end ml-auto md:mt-0 mt-5">
      <div class="ml-auto flex items-center flex-wrap justify-end">
        <app-search-order
          (reloadEventEmitter)="reload()"
          (searchOrderChangeEventEmitter)="searchOrderChange($event)"
          [searchOrder]="searchOrder"
          [searchOrders]="searchOrders"
        ></app-search-order>
        <div class="flex items-center">
          <app-paginator
            [countFrom]="countFrom()"
            [countTo]="countTo()"
            [countTotal]="countTotal()"
            [hasPreviousPage]="canNavigateBack"
            [hasNextPage]="canNavigateForward"
            [pageSize]="pageSize"
            [pageSizeOptions]="[25, 50, 100, 200]"
            (pageSizeChange)="pageSizeChange($event)"
            (goToPreviousPage)="navigateBack()"
            (goToNextPage)="navigateForward()"
          ></app-paginator>
        </div>
      </div>
    </div>
  }
</div>

@if(isError) {
  <app-something-went-wrong
    class="h-[80%]"
    description="{{ 'cannotLoadEmails' | translate }}"
    (retryEmitter)="loadData(true)"
    [showPicture]="false"
  ></app-something-went-wrong>
}

@if(showLoader) {
  <div class="overflow-hidden flex-1">
    <app-skeleton-table></app-skeleton-table>
  </div>
}

@if(!isError && !showLoader) {
  @if(useMobileView) {
    <div class="mobile-container-cards">
      @if(!dataSource.data.length) {
        <div class="text-center mt-3">
          {{ "noEmailsEmailsContentComponent" | translate }}
        </div>
      }

      @for(email of dataSource.data; track email) {
        <div
          [class.!font-bold]="!email.seen"
          (contextmenu)="onRightClick($event, email)"
        >
          <div class="card">
            <div class="checkbox-col">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                color="primary"
                (change)="$event ? selection.toggle(email.emailId) : null"
                [checked]="selection.isSelected(email.emailId)">
              </mat-checkbox>
            </div>

            <mat-card
              class="cursor-pointer mobile-row"
              [class.row-select]="isRowSelected(email.emailId)"
              [class.row-automatic-reassigned]="email?.sourceEmailId && !isRowWithError(email) ? true : false"
              [class.row-error]="isRowWithError(email)"
            >
              <mat-card-content class="flex flex-col flex-1 mb-0" (click)="emailDetails(email)">
                @if(!isColumnShow(false)) {
                  <div class="card-table-row">
                    <div class="card-table-row-value">{{ formatTo(email.from) }}</div>
                  </div>
                }

                @if(!isColumnShow(true)) {
                  <div class="card-table-row">
                    <div class="card-table-row-value">{{ formatTo(email.to) }}</div>
                  </div>
                }

                <div class="card-table-row">
                  <div class="card-table-row-value">{{ email.subject }}</div>
                </div>

                <div class="card-table-row flex-start">
                  <div class="card-table-row-value">{{ getDate(email) | date : "dd/MM/yyyy HH:mm:ss" }}</div>
                </div>

                @if(infoOpenedMailAccount?.type === mailFolderType.Outbox) {
                  <div class="card-table-row flex-start">
                    {{ "emailState" + emailStates[email.state] | translate }}
                  </div>
                }

                @if(email.tags?.length) {
                  <mat-chip-listbox aria-label="Fish selection">
                    @for(tag of email.tags; track tag) {
                      <mat-chip-option>{{ tag.tag }}</mat-chip-option>
                    }
                  </mat-chip-listbox>
                }

                @if(!!email.groups?.length) {
                  <mat-chip-listbox aria-label="Fish selection">
                    @for(group of email.groups; track group.groupId) {
                      <mat-chip style="background: #e9e43b">{{ group.group }}</mat-chip>
                    }
                  </mat-chip-listbox>
                }
              </mat-card-content>

              <div class="icons-mobile-block">
                @if(infoOpenedMailAccount?.type === mailFolderType.Sent ||
                infoOpenedMailAccount?.type === mailFolderType.Archive ||
                infoOpenedMailAccount?.type === mailFolderType.Trash) {
                  <div>
                    @if(email.state === emailStates.Sent && isSentStates(email.state)) {
                      <button
                        (click)="openNotifications(email.emailId)"
                        mat-icon-button
                        matTooltip="{{ 'sent' | translate }}"
                        [attr.aria-label]="'sent' | translate"
                      >
                        <mat-icon>done</mat-icon>
                      </button>
                    }

                    @if(email.state === emailStates.Delivered && isSentStates(email.state)) {
                      <button
                        (click)="openNotifications(email.emailId)"
                        mat-icon-button
                        matTooltip="{{ 'delivered' | translate }}"
                        [attr.aria-label]="'delivered' | translate">
                        <mat-icon color="primary">done_all</mat-icon>
                      </button>
                    }

                    @if(email.state === emailStates.DeliveryError && isSentStates(email.state)) {
                      <button
                        (click)="openNotifications(email.emailId)"
                        mat-icon-button
                        matTooltip="{{ 'deliveryError' | translate }}"
                        [attr.aria-label]="'deliveryError' | translate">
                        <mat-icon style="color: red">clear icon</mat-icon>
                      </button>
                    }

                    @if(email.state === emailStates.NotAccepted && isSentStates(email.state)) {
                      <button
                        (click)="openNotifications(email.emailId)"
                        mat-icon-button
                        matTooltip="{{ 'notAccepted' | translate }}"
                        [attr.aria-label]="'notAccepted' | translate">
                        <mat-icon style="color: red">clear icon</mat-icon>
                      </button>
                    }

                    @if(email.state === emailStates.Accepted && isSentStates(email.state)) {
                      <button
                        (click)="openNotifications(email.emailId)"
                        mat-icon-button
                        color="primary"
                        matTooltip="{{ 'accepted' | translate }}"
                        [attr.aria-label]="'accepted' | translate">
                        <mat-icon color="primary">done</mat-icon>
                      </button>
                    }
                  </div>
                }

                @if(showIcon([
                  mailFolderType.Inbox,
                  mailFolderType.Sent,
                  mailFolderType.Validation,
                  mailFolderType.Spam,
                  mailFolderType.Trash,
                  mailFolderType.Archive
                ])) {
                  <button
                    mat-icon-button
                    [attr.aria-label]="'reply' | translate"
                    matTooltip="{{ 'reply' | translate }}"
                    (click)="reply(email)">
                    <mat-icon>reply</mat-icon>
                  </button>
                }

                @if(showIcon([
                  mailFolderType.Inbox,
                  mailFolderType.Sent,
                  mailFolderType.Validation,
                  mailFolderType.Spam,
                  mailFolderType.Trash,
                  mailFolderType.Archive
                ])) {
                  <button
                    mat-icon-button
                    [attr.aria-label]="'replyAll' | translate"
                    matTooltip="{{ 'replyAll' | translate }}"
                    (click)="replyAll(email)">
                    <mat-icon>reply_all_outline</mat-icon>
                  </button>
                }
              </div>
            </mat-card>
          </div>
        </div>
      }
   </div>
  }

  @if(!useMobileView) {
    <div class="table-container flex-1">
      <table mat-table [dataSource]="dataSource" class="table-row-hover">
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            <div class="table-row">
              {{ "noEmailsEmailsContentComponent" | translate }}
            </div>
          </td>
        </tr>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef style="min-width: 60px">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              color="primary"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td style="min-width: 60px" mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              color="primary"
              (change)="$event ? selection.toggle(row.emailId) : null"
              [checked]="selection.isSelected(row.emailId)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="attachment">
          <th [hidden]="!selectedMailFolderId || !hasAttachments" style="min-width: 60px" mat-header-cell *matHeaderCellDef></th>
          <td [hidden]="!selectedMailFolderId || !hasAttachments" style="min-width: 60px" mat-cell *matCellDef="let emails">
            @if(emails.hasAttachments) {
              <button
                class="hover-disabled"
                mat-icon-button
                [attr.aria-label]="'attachment' | translate"
                matTooltip="{{ 'attachment' | translate }}">
                <mat-icon>attachment_outline</mat-icon>
              </button>
            }
          </td>
        </ng-container>
        <ng-container matColumnDef="notes">
          <th mat-header-cell *matHeaderCellDef></th>
          <td *matCellDef="let emails" mat-cell>
            <div class="flex gap-1">
              <button mat-icon-button aria-label="notes" matTooltip="{{ 'notes' | translate }}" (click)="openNotes(emails.emailId)">
                @if(emails.notesCount > 0) {
                  <mat-icon>sticky_note_2</mat-icon>
                }
                @if(emails.notesCount === 0) {
                  <mat-icon>note_outline</mat-icon>
                }
              </button>
              @if(showMetaButton(emails)) {
                <button mat-icon-button aria-label="meta" matTooltip="{{ 'meta' | translate }}" (click)="openMeta(emails.emailId)">
                  <mat-icon>info_outline</mat-icon>
                </button>
              }
              @if((infoOpenedMailAccount?.type === mailFolderType.Archive ||
                  infoOpenedMailAccount?.type === mailFolderType.Trash) && !showMetaButton(emails)) {
                 <div class="w-[40px]"></div>
              }
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="to">
          <th
            [hidden]="!selectedMailFolderId ? true : isColumnShow(true)"
            [class.first-row]="isColumnShow(false, true)" mat-header-cell *matHeaderCellDef
          >
            {{ "tableRowHeaderTo" | translate }}
          </th>
          <td
            [hidden]="!selectedMailFolderId ? true : isColumnShow(true)"
            [class.first-row-content]="isColumnShow(false, true)"
            mat-cell
            *matCellDef="let emails"
            class="cursor-pointer"
            matTooltip="{{ formatTo(emails.to) }}"
            (mousedown)="onStartRecording($event)"
            (mouseup)="onFinishRecording($event, emails)"
          >
            <div class="to-limitation">
              {{ formatTo(emails.to) }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="from">
          <th
            [hidden]="!selectedMailFolderId ? false : isColumnShow(false)"
            [class.first-row]="!selectedMailFolderId ? true : isColumnShow(true, true)" mat-header-cell *matHeaderCellDef
          >
            {{ "from" | translate }}
          </th>
          <td
            [hidden]="!selectedMailFolderId ? false : isColumnShow(false)"
            [class.first-row-content]="!selectedMailFolderId ? true : isColumnShow(true, true)"
            mat-cell
            *matCellDef="let emails"
            class="cursor-pointer"
            (mousedown)="onStartRecording($event)"
            (mouseup)="onFinishRecording($event, emails)"
          >
            {{ formatTo(emails.from) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="subject">
          <th style="width: 100%; min-width: 200px" mat-header-cell *matHeaderCellDef>{{ "subject" | translate }}</th>
          <td
            style="width: 100%; min-width: 200px"
            mat-cell
            *matCellDef="let emails"
            class="cursor-pointer"
            (mousedown)="onStartRecording($event)"
            (mouseup)="onFinishRecording($event, emails)"
          >
            {{ emails.subject }}
            @if(emails.tags?.length) {
            <mat-chip-listbox aria-label="Fish selection">
              @for(tag of emails.tags; track tag) {
                <mat-chip>{{ tag.tag }}</mat-chip>
              }
            </mat-chip-listbox>
            }
            @if(emails.groups?.length) {
            <mat-chip-listbox aria-label="Fish selection">
              @for(group of emails.groups; track group) {
                <mat-chip style="background: #e9e43b">{{ group.group }}</mat-chip>
              }
            </mat-chip-listbox>
            }
          </td>
        </ng-container>
        <ng-container matColumnDef="source">
          <th
            [hidden]="!selectedMailFolderId || (infoOpenedMailAccount?.type !== 9 && infoOpenedMailAccount?.type !== 8)"
            [class.source-table]="infoOpenedMailAccount?.type === 9 || infoOpenedMailAccount?.type === 8"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ "sourceTableRow" | translate }}
          </th>
          <td
            [hidden]="!selectedMailFolderId || (infoOpenedMailAccount?.type !== 9 && infoOpenedMailAccount?.type !== 8)"
            [class.source-table]="infoOpenedMailAccount?.type === 9 || infoOpenedMailAccount?.type === 8"
            mat-cell
            *matCellDef="let emails"
            class="cursor-pointer"
            (click)="emailDetails(emails)"
          >
            {{ sourceRaw(emails) | translate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="meta">
          <th
            [hidden]="!selectedMailFolderId || infoOpenedMailAccount?.type !== 5"
            [class.source-table]="infoOpenedMailAccount?.type === 5"
            class="w-fit"
            mat-header-cell
            *matHeaderCellDef>
            {{ "meta" | translate }}
          </th>
          <td
            [hidden]="!selectedMailFolderId || infoOpenedMailAccount?.type !== 5"
            [class.source-table]="infoOpenedMailAccount?.type === 5"
            mat-cell
            *matCellDef="let emails"
            class="cursor-pointer"
          >
            <div class="flex">
              @if(showIconById(emails.mailFolderId, emailIcons.RetrySending) && emails.state === 5) {
              <button
                mat-icon-button
                [attr.aria-label]="'retrySending' | translate"
                matTooltip="{{ 'retrySending' | translate }}"
                (click)="retrySend(emails.emailId)">
                <mat-icon>redo</mat-icon>
              </button>
              }
              @if(showIconById(emails.mailFolderId, emailIcons.RetrySending) && isRowWithError(emails)) {
                <button
                  mat-icon-button
                  [attr.aria-label]="'moveToDraft' | translate"
                  matTooltip="{{ 'moveToDraft' | translate }}"
                  (click)="moveToDraft(emails.emailId)">
                  <mat-icon>folder_open</mat-icon>
                </button>
              }
              @if(showMetaButton(emails, false)) {
                <button
                  mat-icon-button
                  [attr.aria-label]="'meta' | translate"
                  matTooltip="{{ 'meta' | translate }}"
                  (click)="openMeta(emails.emailId)">
                  <mat-icon>info_outline</mat-icon>
                </button>
              }
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="folder">
          <th [hidden]="!isShowFolderColumn" [class.source-table]="isShowFolderColumn" mat-header-cell *matHeaderCellDef>
            {{ "folder" | translate }}
          </th>
          <td
            [hidden]="!isShowFolderColumn"
            [class.source-table]="isShowFolderColumn"
            mat-cell
            *matCellDef="let emails"
            class="cursor-pointer"
            (click)="emailDetails(emails)"
          >
            {{ folderName(emails.mailFolderId) | translate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th style="min-width: 170px" mat-header-cell *matHeaderCellDef>{{ "date" | translate }}</th>
          <td style="min-width: 170px" mat-cell *matCellDef="let emails" class="cursor-pointer">
            {{ getDate(emails) | date : "dd/MM/yyyy HH:mm:ss" }}
            <div
              [style.right]="
              infoOpenedMailAccount?.type !== mailFolderType.Sent &&
              infoOpenedMailAccount?.type !== mailFolderType.Outbox &&
              infoOpenedMailAccount?.type !== mailFolderType.Archive &&
              infoOpenedMailAccount?.type !== mailFolderType.Trash &&
              !isShowFolderColumn
                ? '63px'
                : '200px'
            "
              class="right-hover-menu"
            >
              <app-loading-icon-button
                label="validate"
                icon="check_circle_outline"
                [showButton]="
                !!showIconById(emails.mailFolderId, emailIcons.Validate) &&
                (emails.validationState === emailValidationState.NotValidated || emails.validationState === emailValidationState.New) &&
                !isEmailValidating(emails.emailId)
              "
                [disabled]="!!validatingEmailIds.length"
                [showLoading]="isEmailValidating(emails.emailId)"
                (clickButton)="setValidated(emailValidationState.Validated, emails.emailId)">
              </app-loading-icon-button>
              <app-loading-icon-button
                label="deleteFinalMail"
                icon="delete_forever"
                [showButton]="(infoOpenedMailAccount?.type === mailFolderType.Trash ||
                infoOpenedMailAccount?.type === mailFolderType.Spam) && canFinalDelete"
                mat-icon-button
                (clickButton)="finalDelete(emails.emailId)">
              </app-loading-icon-button>
              <app-loading-icon-button
                label="reply"
                icon="reply"
                [showButton]="replyingEmailId !== emails.emailId && showIconById(emails.mailFolderId, emailIcons.Reply)"
                [disabled]="!!replyingAllEmailId || !!forwardingEmailId || !!replyingEmailId"
                [showLoading]="replyingEmailId === emails.emailId"
                (clickButton)="reply(emails)">
              </app-loading-icon-button>
              <app-loading-icon-button
                label="replyAll"
                icon="reply_all_outline"
                [showButton]="replyingAllEmailId !== emails.emailId && showIconById(emails.mailFolderId, emailIcons.ReplyAll)"
                [disabled]="!!replyingAllEmailId || !!forwardingEmailId || !!replyingEmailId"
                [showLoading]="replyingAllEmailId === emails.emailId"
                (clickButton)="replyAll(emails)">
              </app-loading-icon-button>
              <app-loading-icon-button
                label="forward"
                icon="shortcut"
                [showButton]="forwardingEmailId !== emails.emailId && showIconById(emails.mailFolderId, emailIcons.Forward)"
                [disabled]="!!replyingAllEmailId || !!forwardingEmailId || !!replyingEmailId"
                [showLoading]="forwardingEmailId === emails.emailId"
                (clickButton)="forward(emails.emailId)">
              </app-loading-icon-button>
              @if(showIconById(emails.mailFolderId, emailIcons.Reassign) && !searchParams.reassigned) {
                <button
                  mat-icon-button
                  [attr.aria-label]="'reassign' | translate"
                  (click)="reassign(emails.emailId)"
                  matTooltip="{{ 'reassign' | translate }}">
                  <mat-icon>forward_to_inbox</mat-icon>
                </button>
              }
              @if(showIconById(emails.mailFolderId, emailIcons.Groups)) {
              <button
                mat-icon-button
                [attr.aria-label]="'groups' | translate"
                matTooltip="{{ 'groups' | translate }}"
                (click)="openAddGroupDialog(emails)">
                <mat-icon>groups</mat-icon>
              </button>
              }

              @if(showIconById(emails.mailFolderId, emailIcons.Tags)) {
              <button
                mat-icon-button
                [attr.aria-label]="'tags' | translate"
                matTooltip="{{ 'tags' | translate }}"
                (click)="openAddTagDialog(emails)">
                <mat-icon>local_offer</mat-icon>
              </button>
              }

              @if(showIconById(emails.mailFolderId, emailIcons.Seen) && !emails.seen) {
              <button
                mat-icon-button
                matTooltip="{{ 'setRead' | translate }}"
                (click)="setSeen(true, emails)">
                <mat-icon>visibility_outline</mat-icon>
              </button>
              }

              @if(showIconById(emails.mailFolderId, emailIcons.Seen) && emails.seen) {
              <button
                mat-icon-button
                matTooltip="{{ 'setUnread' | translate }}"
                (click)="setSeen(false, emails)">
                <mat-icon>visibility_off_outline</mat-icon>
              </button>
              }

              @if(showIconById(emails.mailFolderId, emailIcons.Spam) && !emails.isSpam) {
              <button
                mat-icon-button
                matTooltip="{{ 'markAsSpam' | translate }}"
                (click)="setSpam(emails.emailId)">
                <mat-icon>report_outline</mat-icon>
              </button>
              }

              @if(showIconById(emails.mailFolderId, emailIcons.Delete) && !emails.deleted) {
              <button
                mat-icon-button
                [attr.aria-label]="'delete' | translate"
                matTooltip="{{ 'delete' | translate }}"
                (click)="delete(emails.emailId)">
                <mat-icon>delete</mat-icon>
              </button>
              }

              <app-loading-icon-button
                label="downloadSingleEml"
                icon="download"
                [showButton]="showIconById(emails.mailFolderId, emailIcons.DownloadEML) && downloadingSingleEMLEmailId !== emails.emailId"
                [showLoading]="downloadingSingleEMLEmailId === emails.emailId"
                (clickButton)="downloadSingleEML(emails.emailId)">
              </app-loading-icon-button>

              <app-loading-icon-button
                label="downloadEml"
                icon="download_for_offline"
                [showButton]="showIconById(emails.mailFolderId, emailIcons.DownloadEML) && downloadingEMLEmailId !== emails.emailId"
                [showLoading]="downloadingEMLEmailId === emails.emailId"
                (clickButton)="downloadEML(emails.emailId)">
              </app-loading-icon-button>

              @if(showIconById(emails.mailFolderId, emailIcons.Restore) && emails.deleted) {
              <button
                mat-icon-button
                [attr.aria-label]="'restore' | translate"
                matTooltip="{{ 'restore' | translate }}"
                (click)="restoreFromTrash(emails.emailId)">
                <mat-icon>restore_outline</mat-icon>
              </button>
              }

              @if(showIconById(emails.mailFolderId, emailIcons.NotSpam) && emails.isSpam) {
              <button
                mat-icon-button
                [attr.aria-label]="'markAsNotSpam' | translate"
                (click)="restoreMoveFolderChoose([emails.emailId], 'restoreOneSpam')"
                matTooltip="{{ 'markAsNotSpam' | translate }}">
                <mat-icon>check</mat-icon>
              </button>
              }
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="state">
          <th
            [hidden]="
            !selectedMailFolderId ||
            (infoOpenedMailAccount?.type !== mailFolderType.Sent &&
            infoOpenedMailAccount?.type !== mailFolderType.Archive &&
            infoOpenedMailAccount?.type !== mailFolderType.Outbox &&
            infoOpenedMailAccount?.type !== mailFolderType.Trash)
          "
            [style]="infoOpenedMailAccount?.type === mailFolderType.Outbox ? 'min-width: 200px' : 'min-width: 100px'"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ "state" | translate }}
          </th>
          <td
            [hidden]="
            !selectedMailFolderId ||
            (infoOpenedMailAccount?.type !== mailFolderType.Sent &&
            infoOpenedMailAccount?.type !== mailFolderType.Archive &&
            infoOpenedMailAccount?.type !== mailFolderType.Outbox &&
            infoOpenedMailAccount?.type !== mailFolderType.Trash)
          "
            [style]="infoOpenedMailAccount?.type === mailFolderType.Outbox ? 'min-width: 200px' : 'min-width: 100px'"
            mat-cell
            *matCellDef="let emails"
            class="cursor-pointer"
          >
            @if(infoOpenedMailAccount?.type !== mailFolderType.Outbox) {
            <ng-container>
              @if((emails.state === emailStates.Sent || emails.state === emailStates.Downloaded) &&
                isSentStates(emails.state) && emails.sourceFolderType !== mailFolderType.Inbox) {
                <button
                  (click)="openNotifications(emails.emailId)"
                  mat-icon-button
                  matTooltip="{{ 'sent' | translate }}"
                  [attr.aria-label]="'sent' | translate">
                  <mat-icon>done</mat-icon>
                </button>
              }
              @if(emails.state === emailStates.Delivered && isSentStates(emails.state)) {
              <button
                (click)="openNotifications(emails.emailId)"
                mat-icon-button
                matTooltip="{{ 'delivered' | translate }}"
                [attr.aria-label]="'delivered' | translate">
                <mat-icon color="primary">done_all</mat-icon>
              </button>
              }
              @if(emails.state === emailStates.DeliveryError && isSentStates(emails.state)) {
              <button
                (click)="openNotifications(emails.emailId)"
                mat-icon-button
                matTooltip="{{ 'deliveryError' | translate }}"
                [attr.aria-label]="'deliveryError' | translate">
                <mat-icon style="color: red">clear icon</mat-icon>
              </button>
              }

              @if(emails.state === emailStates.NotAccepted && isSentStates(emails.state)) {
              <button
                (click)="openNotifications(emails.emailId)"
                mat-icon-button
                matTooltip="{{ 'notAccepted' | translate }}"
                [attr.aria-label]="'notAccepted' | translate">
                <mat-icon style="color: red">clear icon</mat-icon>
              </button>
              }

              @if(emails.state === emailStates.Accepted && isSentStates(emails.state)) {
              <button
                (click)="openNotifications(emails.emailId)"
                mat-icon-button
                color="primary"
                matTooltip="{{ 'accepted' | translate }}"
                [attr.aria-label]="'accepted' | translate">
                <mat-icon color="primary">done</mat-icon>
              </button>
              }
            </ng-container>
            }
            @if(infoOpenedMailAccount?.type === mailFolderType.Outbox) {
              <ng-container>
              {{ "emailState" + emailStates[emails.state] | translate }}
              </ng-container>
            }
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [class.!font-bold]="!row.seen"
          [class.row-select]="isRowSelected(row.emailId)"
          [class.row-automatic-reassigned]="row?.sourceEmailId && !isRowWithError(row)"
          [class.row-error]="isRowWithError(row)"
          (contextmenu)="onRightClick($event, row)"
        >
        </tr>
      </table>
    </div>
  }
}

<ng-template #metaDrawer>
  <app-email-meta [emailId]="metaMailId" [title]="'meta'"> </app-email-meta>
</ng-template>

<ng-template #notesDrawer>
  <app-email-note-list
    [emailId]="notesMailId"
    [title]="'notes'"
    (refreshNotes)="refreshNotes()"
  ></app-email-note-list>
</ng-template>

<ng-template #auditDrawer>
  <app-email-audit
    [title]="'audit'"
    [emailId]="auditMailId"
  ></app-email-audit>
</ng-template>


<ng-template #createOrUpdateDrawer>
  @if(infoOpenedMailAccount.acc !== undefined) {
    <app-email-create-or-update
      [mailAccountId]="infoOpenedMailAccount.acc"
      [emailId]="updatedEmail?.emailId"
      [useMobileView]="useMobileView"
      (submitted)="closeCreateOrUpdateModalForm($event)"
      (deleteFromData)="deleteFromData($event)"
      (changeDraftData)="changeDraftData($event)">
    </app-email-create-or-update>
  }
</ng-template>

<ng-template #emailDrawer>
  @if(
  openedEmail
  && openedEmail?.emailId
  && infoOpenedMailAccount
  && infoOpenedMailAccount.acc !== undefined
  && infoOpenedMailAccount.type !== undefined
  && infoOpenedMailAccount.folder !== undefined
  ) {
    <app-email-detail-dialog
      [mailFolderId]="infoOpenedMailAccount.folder"
      [currentFolderType]="infoOpenedMailAccount.type"
      [emailId]="openedEmail.emailId"
      (closeDrawer)="closeEmailModalForm($event)"
      [useMobileView]="useMobileView"
      (getInfoAboutOpenedEmail)="getInfoAboutOpenedEmail($event)"
      (setSeenOpened)="setSeenOpened($event)"
      (setValidationStateForEmail)="setValidationStateForEmail($event)"
      (deleteFromData)="deleteFromData($event)">
    </app-email-detail-dialog>
  }
</ng-template>

<ng-template #importEMLIsDrawer>
  @if(!!selectedMailFolderId && infoOpenedMailAccount.acc !== undefined) {
    <app-import-eml
      (loading)="loadingImportEML($event)"
      [mailAccountId]="infoOpenedMailAccount.acc"
      [mailFolderId]="selectedMailFolderId"
      (closeDrawer)="loadData(false, true)"
    ></app-import-eml>
  }
</ng-template>

<ng-template #notificationDrawer>
  <app-email-notifications-dialog
    [title]="'notifications'"
    [emailId]="notificationMailId"
    [webCode]="webCode"
    (openEmailDetails)="openEmailDetails($event)">
  </app-email-notifications-dialog>
</ng-template>
} @else {
  <div class="flex justify-center items-center h-full">
    <p class="text-center m-[auto]">{{ "noMailAccountIsAvailableYet" | translate }}</p>
  </div>
}

<div
  style="visibility: hidden; position: fixed"
  [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y"
  [matMenuTriggerFor]="mailMenu"></div>

<mat-menu #mailMenu="matMenu" class="menu">
  <ng-template matMenuContent let-item="item">
    @if(showIconById(item.mailFolderId, emailIcons.Reply)) {
    <button mat-menu-item (click)="reply(item)">
      <mat-icon>reply</mat-icon>{{ "reply" | translate }}
    </button>
    }
    @if(showIconById(item.mailFolderId, emailIcons.ReplyAll)) {
    <button
      mat-menu-item
      [attr.aria-label]="'replyAll' | translate"
      (click)="replyAll(item)">
      <mat-icon>reply_all_outline</mat-icon>{{ "replyAll" | translate }}
    </button>
    }

    @if(showIconById(item.mailFolderId, emailIcons.Forward)) {
    <button
      mat-menu-item
      [attr.aria-label]="'forward' | translate"
      (click)="forward(item.emailId)">
      <mat-icon>shortcut</mat-icon>{{ "forward" | translate }}
    </button>
    }

    @if(showIconById(item.mailFolderId, emailIcons.Reassign) && !searchParams.reassigned) {
    <button
      mat-menu-item
      aria-label="Reassign"
      (click)="reassign(item.emailId)"
    >
      <mat-icon>forward_to_inbox</mat-icon>{{ "reassign" | translate }}
    </button>
    }

    @if(showIconById(item.mailFolderId, emailIcons.Move)) {
    <button mat-menu-item aria-label="Move" (click)="move(item.emailId)">
      <mat-icon>folder_open</mat-icon> {{ "moveToFolder" | translate }}
    </button>
    }

    @if(showIconById(item.mailFolderId, emailIcons.Groups)) {
    <button
      mat-menu-item
      [attr.aria-label]="'groups' | translate"
      (click)="openAddGroupDialog(item)">
      <mat-icon>groups</mat-icon>{{ 'groups' | translate }}
    </button>
    }

    @if(showIconById(item.mailFolderId, emailIcons.Archive)) {
    <button
      mat-menu-item
      aria-label="Archive"
      (click)="setSelectedArchive(item.emailId)">
        <mat-icon>archive</mat-icon>{{ "archiveIconLabel" | translate }}
    </button>
    }

    @if(showIconById(item.mailFolderId, emailIcons.Tags)) {
    <button mat-menu-item aria-label="Tags" (click)="openAddTagDialog(item)">
      <mat-icon>local_offer</mat-icon>{{ "tags" | translate }}
    </button>
    }

    @if(showIconById(item.mailFolderId, emailIcons.Seen)) {
    <button mat-menu-item (click)="openAudit(item.emailId)">
      <mat-icon>verified_outline</mat-icon>{{ "audit" | translate }}
    </button>
    }

    @if(showIconById(item.mailFolderId, emailIcons.Seen) && !item.seen) {
    <button mat-menu-item (click)="setSeen(true, item)">
      <mat-icon>visibility_outline</mat-icon>{{ "setRead" | translate }}
    </button>
    }

    @if(showIconById(item.mailFolderId, emailIcons.Seen) && item.seen) {
    <button mat-menu-item (click)="setSeen(false, item)">
      <mat-icon>visibility_off_outline</mat-icon>{{ "setUnread" | translate }}
    </button>
    }

    @if(showIconById(item.mailFolderId, emailIcons.RetrySending) && item.state === 5) {
    <button
      mat-menu-item
      [attr.aria-label]="'retrySending' | translate"
      matTooltip="{{ 'retrySending' | translate }}"
        (click)="retrySend(item.emailId)">
        <mat-icon>redo</mat-icon>{{ "retrySending" | translate }}
    </button>
    }

    @if(showIconById(item.mailFolderId, emailIcons.Spam) && !item.isSpam) {
    <button mat-menu-item (click)="setSpam(item.emailId)">
      <mat-icon>report_outline</mat-icon>{{ "markAsSpam" | translate }}
    </button>
    }

    @if((showIconById(item.mailFolderId, emailIcons.Delete)) && !item.deleted) {
    <button
      mat-menu-item
      (click)="delete(item.emailId)">
      <mat-icon>delete</mat-icon>{{ "delete" | translate }}
    </button>
    }

    @if((infoOpenedMailAccount?.type === mailFolderType.Trash || infoOpenedMailAccount?.type === mailFolderType.Spam) && canFinalDelete) {
    <button
      mat-menu-item
      [attr.aria-label]="'deleteFinalMail' | translate"
      matTooltip="{{ 'deleteFinalMail' | translate }}"
        (click)="finalDelete(item.emailId)"
      >
        <mat-icon>delete_forever</mat-icon>{{ "deleteFinalMail" | translate }}
    </button>
    }

    @if(showIconById(item.mailFolderId, emailIcons.RetrySending)
    && (item.state === emailStates.VirusDetected || item.state === emailStates.SendError)) {
    <button
      mat-menu-item
      (click)="moveToDraft(item.emailId)">
      <mat-icon>folder_open</mat-icon>{{ "moveToDraft" | translate }}
    </button>
    }

    @if(showIconById(item.mailFolderId, emailIcons.Restore) && item.deleted) {
    <button
      mat-menu-item
      (click)="restoreFromTrash(item.emailId)">
      <mat-icon>restore_outline</mat-icon>{{ "restore" | translate }}
    </button>
    }

    @if(showIconById(item.mailFolderId, emailIcons.DownloadEML)) {
    <button
      mat-menu-item
      [attr.aria-label]="'downloadSingleEml' | translate"
      (click)="downloadSingleEML(item.emailId)">
      <mat-icon>download</mat-icon>{{ "downloadSingleEml" | translate }}
    </button>
    }

    @if(showIconById(item.mailFolderId, emailIcons.DownloadEML)) {
    <button
      mat-menu-item
      [attr.aria-label]="'downloadEml' | translate"
      (click)="downloadEML(item.emailId)">
      <mat-icon>download_for_offline</mat-icon>{{ "downloadEml" | translate }}
    </button>
    }

    @if(showIconById(item.mailFolderId, emailIcons.NotSpam) && item.isSpam) {
    <button
      mat-menu-item
      (click)="restoreMoveFolderChoose([item.emailId], 'restoreOneSpam')">
      <mat-icon>check</mat-icon>{{ "markAsNotSpam" | translate }}
    </button>
    }

    @if(showIconById(item.mailFolderId, emailIcons.RestoreFromArchive)) {
    <button
      mat-menu-item
      [attr.aria-label]="'restoreFromArchive' | translate"
      (click)="restoreFromArchive(item.emailId)">
      <mat-icon>assignment_turned_in</mat-icon>{{ "restoreArchive" | translate }}
    </button>
    }

    @if(showIconById(item.mailFolderId, emailIcons.Validate) &&
    (item.validationState === emailValidationState.NotValidated || item.validationState === emailValidationState.New) &&
    !isEmailValidating(item.emailId)) {
    <button
      mat-menu-item
      [attr.aria-label]="'validate' | translate"
      [disabled]="!!validatingEmailIds.length"
      (click)="setValidated(emailValidationState.Validated, item.emailId)">
      <mat-icon>check_circle_outline</mat-icon>{{ "validate" | translate }}
    </button>
    }
    @if(showMetaButton(item)) {
      <button mat-menu-item aria-label="meta" matTooltip="{{ 'meta' | translate }}" (click)="openMeta(item.emailId)">
        <mat-icon>info_outline</mat-icon>{{ "meta" | translate }}
      </button>
    }
  </ng-template>
</mat-menu>
